import { useDispatch } from "react-redux";
import styles from "./salesFunnelTop.module.scss";
import videoAnimation from "src/assets/howItWorks/videoAnimation.mp4";
import {
  setIsShowPricingModalAC,
  setIsShowRegisterModalAC,
} from "src/store/actions";
import logoImg from "src/assets/homePage/companyLogo.png";
import videoPreviewImg from "src/assets/videoPreview.jpg";

const SalesFunnelTop: React.FC<any> = ({ title, subtitle }: any) => {
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  const handleLogout = () => {
    if (window.Intercom) {
      window.Intercom("shutdown");
    }
    window.location.reload();
    localStorage.removeItem("token");
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoutBtnWrap}>
        {token && (
          <button className={styles.logoutBtn} onClick={handleLogout}>
            Logout
          </button>
        )}
      </div>
      <img width="200px" src={logoImg} alt="logo" />
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      <p
        className={styles.subtitle}
        dangerouslySetInnerHTML={{ __html: subtitle }}
      />
      <div className={styles.buttonsWrap}>
        <div className={styles.getStartedButton}>
          <button
            onClick={
              token
                ? () => dispatch(setIsShowPricingModalAC(true))
                : () => dispatch(setIsShowRegisterModalAC(true))
            }
          >
            Get started for free
          </button>
        </div>
      </div>
      <div className={styles.videoSection}>
        <div className={styles.video}>
          <video width="100%" height="100%" controls poster={videoPreviewImg}>
            <source src={videoAnimation} type="video/mp4" />
          </video>
        </div>
      </div>
      <div className={styles.formPreview}>
        <p className={styles.embededTitle}>Try It For Yourself</p>
        <iframe
          src="https://epoxy.leadquiz.com"
          width="100%"
          height="500px350px"
          scrolling="no"
          data-cookieconsent="ignore"
          id="leadquiz_form"
        ></iframe>
      </div>
    </div>
  );
};

export default SalesFunnelTop;
