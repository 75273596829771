import React from "react";
import styles from "./answerItemButtonWithImage.module.scss";
import { IAnswerItemButtonWithImage } from "src/pages/interfaces/interfaces";
import COLORS from "src/_utils/colors/colors";

const AnswerItemButtonWithImage: React.FC<IAnswerItemButtonWithImage> = ({
  question,
  icon,
  handleOptionChange,
  el,
  selectedAnswer,
  isMultipleSelect,
  color = COLORS.BLUE,
}: IAnswerItemButtonWithImage) => {
  const isSelected = isMultipleSelect
    ? selectedAnswer.includes(el)
    : el === selectedAnswer;

  return (
    <label
      className={styles.wrapper}
      style={{ border: isSelected ? `2px solid ${color}` : "none" }}
    >
      <input
        type={isMultipleSelect ? "checkbox" : "radio"}
        id={question}
        value={question}
        onClick={handleOptionChange}
        style={{ accentColor: color }}
      />
      {icon !== "" && icon !== undefined && (
        <img src={icon} alt="question" className={styles.radioButton} />
      )}
      <p className={styles.question}>{question}</p>
    </label>
  );
};

export default AnswerItemButtonWithImage;
