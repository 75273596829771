import React, { useRef } from "react";
import styles from "./fileUploader.module.scss";
import { IFileUploader } from "../interfaces/interfaces";
import uploadIcon from "src/assets/upload.svg";

const FileUploader: React.FC<IFileUploader> = ({
  handleFileSelect,
  fileName,
  isShowIcon = true,
  color,
}: IFileUploader) => {
  const fileInputRef = useRef(null);

  const handleSelectImageClick = () => {
    //@ts-ignore
    fileInputRef.current.click();
  };

  return (
    <div className={styles.fileUploaderWrapper}>
      <button
        className={styles.fileUploaderButton}
        onClick={handleSelectImageClick}
        style={{ backgroundColor: color }}
      >
        <p>{fileName ?? "Click to upload your logo"}</p>
        {isShowIcon && <img height={50} src={uploadIcon} alt="upload" />}
      </button>
      <input
        type="file"
        className={styles.fileUploaderInput}
        ref={fileInputRef}
        onChange={handleFileSelect}
      />
    </div>
  );
};

export default FileUploader;
