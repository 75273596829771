import styles from "./businessProfileSettings.module.scss";
import { useEffect, useState } from "react";
import { validateEmail } from "src/_utils/emailValidator";
import {
  deleteBusinessProfileSettingsApi,
  editBusinessProfileSettingsApi,
  getBusinessProfileSettingsApi,
  postBusinessProfileSettingsApi,
} from "src/_utils/api/api";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import "react-phone-number-input/style.css";
import deleteIcon from "src/assets/trash.svg";
import editIcon from "src/assets/edit.svg";
import BusinessProfileModal from "./businessProfileModal/businessProfileModal";
import ActionModal from "src/_elements/actionModal/actionModal";

const BusinessProfileSettings = () => {
  const [profiles, setProfiles] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isShowAddBusinessProfileModal, setIsShowAddBusinessProfileModal] =
    useState(false);
  const [isShowEditBusinessProfileModal, setIsShowEditBusinessProfileModal] =
    useState(false);
  const [selectedBusinessProfileData, setSelectedBusinessProfileData] =
    useState<any>(null);
  const [
    isShowDeleteBusinessProfileModal,
    setisShowDeleteBusinessProfileModal,
  ] = useState(false);
  const [businessProfileIndex, setBusinessProfileIndex] = useState(0);

  useEffect(() => {
    getBusinessProfileSettingsApi().then((res) => {
      if (res.data.data) {
        setProfiles(res.data.data);
      }
    });
    setIsSaved(false);
  }, [isSaved]);

  const handleSave = (businessProfileData: any, handleAxiosAction: any) => {
    setErrorMessage("");

    const validatedPhoneNumber = parsePhoneNumberFromString(
      String(businessProfileData.phone)
    );

    const urlPattern = /^(http:\/\/|https:\/\/)/;

    if (
      businessProfileData.businessName === "" ||
      businessProfileData.privacyPolicyLink === "" ||
      businessProfileData.email === "" ||
      businessProfileData.phone === ""
    ) {
      setErrorMessage("Please fill in all fields");
    } else if (!validateEmail(businessProfileData.email)) {
      setErrorMessage("Invalid email");
    } else if (!validatedPhoneNumber || !validatedPhoneNumber.isValid()) {
      setErrorMessage("Invalid phone number");
    } else if (!urlPattern.test(businessProfileData.privacyPolicyLink)) {
      setErrorMessage(
        "Privacy policy link must start with http:// or https://"
      );
    } else {
      setIsLoading(true);

      handleAxiosAction(businessProfileData);
    }
  };

  const handlePostNewBusinessProfile = (businessProfileData: any) => {
    const payload = {
      businessName: businessProfileData.businessName,
      email: businessProfileData.email,
      phone: businessProfileData.phone,
      privacyPolicyLink: businessProfileData.privacyPolicyLink,
    };

    postBusinessProfileSettingsApi(payload).then(() => {
      setIsLoading(false);
      setIsSaved(true);
    });
  };

  const handleEditNewBusinessProfile = (businessProfileData: any) => {
    const payload = {
      businessName: businessProfileData.businessName,
      email: businessProfileData.email,
      phone: businessProfileData.phone,
      privacyPolicyLink: businessProfileData.privacyPolicyLink,
    };

    editBusinessProfileSettingsApi(
      payload,
      selectedBusinessProfileData.id
    ).then(() => {
      setIsLoading(false);
      setIsSaved(true);
    });
  };

  const handleRemoveBusinessProfile = () => {
    deleteBusinessProfileSettingsApi(profiles[businessProfileIndex].id).then(
      () => setIsSaved(true)
    );
    handleCloseDeleteModal();
  };

  const handleEditBusinessProfile = (index: any) => {
    setIsShowEditBusinessProfileModal(true);
    setSelectedBusinessProfileData(profiles[index]);
  };

  const handleCloseAddBusinessProfileModal = () => {
    setIsShowAddBusinessProfileModal(false);
  };

  const handleCloseEditBusinessProfileModal = () => {
    setIsShowEditBusinessProfileModal(false);
  };

  const handleOpenDeleteModal = (index: number) => {
    setBusinessProfileIndex(index);
    setisShowDeleteBusinessProfileModal(true);
  };

  const handleCloseDeleteModal = () => {
    setisShowDeleteBusinessProfileModal(false);
  };

  return (
    <>
      <div className={styles.wrapper}>
        <p className={styles.title}>Business profiles settings</p>
        <div className={styles.content}>
          {profiles &&
            profiles.map((profile: any, index: number) => (
              <div key={index} className={styles.inputsWrap}>
                <div className={styles.buttonsWrap}>
                  <button
                    onClick={() => handleEditBusinessProfile(index)}
                    className={styles.actionBtn}
                  >
                    <img src={editIcon} alt="edit" />
                  </button>
                  <button
                    onClick={() => handleOpenDeleteModal(index)}
                    className={styles.actionBtn}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </button>
                </div>
                <p className={styles.businessLabel}>
                  Business name: <br/><span>{profile.businessName}</span>
                </p>
                <p className={styles.businessLabel}>
                  Business email: <br/><span>{profile.email}</span>
                </p>
                <p className={styles.businessLabel}>
                  Business phone: <br/><span>{profile.phone}</span>
                </p>
                <p className={styles.businessLabel}>
                  Privacy polict link: <br/><span>{profile.privacyPolicyLink}</span>
                </p>
              </div>
            ))}
        </div>
        <div className={styles.btnWrap}>
          <button
            onClick={() => setIsShowAddBusinessProfileModal(true)}
            className={styles.addBtn}
          >
            Add business profile
          </button>
        </div>
      </div>
      <BusinessProfileModal
        title="Add business profile"
        isShow={isShowAddBusinessProfileModal}
        handleClose={handleCloseAddBusinessProfileModal}
        handleSave={handleSave}
        errorMessage={errorMessage}
        isLoading={isLoading}
        isSaved={isSaved}
        handleAxiosAction={handlePostNewBusinessProfile}
      />
      <BusinessProfileModal
        title={`Edit "${
          profiles && profiles[businessProfileIndex]?.businessName
        }" business profile`}
        isShow={isShowEditBusinessProfileModal}
        handleClose={handleCloseEditBusinessProfileModal}
        handleSave={handleSave}
        errorMessage={errorMessage}
        isLoading={isLoading}
        isSaved={isSaved}
        businessProfileData={selectedBusinessProfileData}
        handleAxiosAction={handleEditNewBusinessProfile}
      />
      <ActionModal
        title={`Delete "${
          profiles && profiles[businessProfileIndex]?.businessName
        }?" business profile`}
        subtitle="This will permanently delete the business profile."
        isShowModal={isShowDeleteBusinessProfileModal}
        handleCloseModal={handleCloseDeleteModal}
        handleAction={handleRemoveBusinessProfile}
        actionButtonText="Delete"
      />
    </>
  );
};

export default BusinessProfileSettings;
