import React from "react";
import { IProgressBar } from "../interfaces/interfaces";
import COLORS from "src/_utils/colors/colors";

const ProgressBar: React.FC<IProgressBar> = ({
  currentStep,
  totalFormPagesCount,
  color = COLORS.BLUE,
}: IProgressBar) => {
  const progressPercentage = (currentStep / totalFormPagesCount) * 100;

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#e0e0df",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: `${progressPercentage}%`,
          height: "5px",
          backgroundColor: color,
          transition: "width 0.5s ease-in-out",
        }}
      />
    </div>
  );
};

export default ProgressBar;
