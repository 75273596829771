import React, { useEffect, useState } from "react";
import styles from "./formBuilder.module.scss";
import Header from "./header/header";
import ChooseLogoAndName from "./formBuilderSteps/chooseLogoAndName/chooseLogoAndName";
import Footer from "./footer/footer";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "src/_elements/progressBar/progressBar";
import ChooseQuestions from "./formBuilderSteps/chooseQuestions/chooseQuestions";
import ChooseAnswerOptions from "./formBuilderSteps/chooseAnswerOptions/chooseAnswerOptions";
import EndingPage from "./formBuilderSteps/endingPage/endingPage";
import {
  setCompanyLogoAC,
  setCompanyNameAC,
  setCurrentCreateFormPageAC,
  setCurrentFormPageAC,
  setEndingPageTextAC,
  setFormColorThemeAC,
  setHSWCYOTitleAC,
  setIsAnyAnswerOptionsAC,
  setIsHideLogoAC,
  setIsHidePoweredLabelAC,
  setQuestionsAC,
} from "src/store/actions";
import ChooseConditions from "./formBuilderSteps/chooseConditions/chooseConditions";
import { useNavigate, useParams } from "react-router-dom";
import ROUTES from "src/_utils/routes/routes";
import { Helmet } from "react-helmet";
import ChooseEndingPage from "./formBuilderSteps/chooseEndingPage/chooseEndingPage";
import ChooseHowShallWeContactYou from "./formBuilderSteps/chooseHowShallWeContactYou/chooseHowShallWeContactYou";
import {
  getCurrentUserPlanApi,
  getFormTemplateByIdApi,
} from "src/_utils/api/api";
import ChooseTheme from "./formBuilderSteps/chooseTheme/chooseTheme";
import ChooseIsShowPowered from "./formBuilderSteps/chooseIsShowPowered/chooseIsShowPowered";

const FormBuilder: React.FC<any> = ({
  editFormsData,
  createFormTemplateFlow,
  editFormTemplateFlow,
}: any) => {
  const currentIndex = useSelector((state: any) => state.currentCreateFormPage);
  const selectedFormTemplateId = useSelector(
    (state: any) => state.selectedFormTemplateId
  );

  const { id } = useParams();

  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentFormTemplateImg, setCurrentFormTemplateImg] = useState(null);
  const [formTemplateId, setFormTemplateId] = useState<any>(null);
  const [isCanHidePoweredLabel, setIsCanHidePoweredLabel] = useState(false);

  useEffect(() => {
    const url = window.location.href;
    const queryString = url.split("?")[1];
    const params = new URLSearchParams(queryString);
    const templateId = params.get("form-template");

    setFormTemplateId(templateId);
  }, [selectedFormTemplateId]);

  const content = [
    {
      id: "tour-company",
      component: (
        <ChooseLogoAndName
          editFormsData={editFormsData}
          editFormTemplateFlow={editFormTemplateFlow}
        />
      ),
    },
    { id: "tour-questions", component: <ChooseQuestions /> },
    { id: "tour-answers", component: <ChooseAnswerOptions /> },
    { id: "tour-conditions", component: <ChooseConditions /> },
    { id: "tour-theme", component: <ChooseTheme /> },
    {
      id: "tour-powered",
      component: (
        <ChooseIsShowPowered isCanHidePoweredLabel={isCanHidePoweredLabel} />
      ),
    },
    {
      id: "tour-contact",
      component: (
        <ChooseHowShallWeContactYou
          otherInfo={
            editFormsData?.formData?.formsData?.howShallWeContactYou?.otherInfo
          }
        />
      ),
    },
    { id: "tour-ending", component: <ChooseEndingPage /> },
    {
      id: "tour-ready",
      component: (
        <EndingPage
          editFormsData={editFormsData}
          createFormTemplateFlow={createFormTemplateFlow}
          editFormTemplateFlow={editFormTemplateFlow}
          currentFormTemplateImg={currentFormTemplateImg}
        />
      ),
    },
  ];

  useEffect(() => {
    if (editFormsData) {
      dispatch(setCompanyNameAC(editFormsData?.form?.name));
      dispatch(setQuestionsAC(editFormsData?.formData?.formsData?.questions));
      dispatch(
        setHSWCYOTitleAC(
          editFormsData?.formData?.formsData?.howShallWeContactYou?.title
        )
      );
      dispatch(
        setEndingPageTextAC(editFormsData?.formData?.formsData?.endingPageText)
      );
      editFormsData?.formData?.formsData?.questions.map((el: any) => {
        el.answerOptions &&
          el.answerOptions[0] &&
          dispatch(setIsAnyAnswerOptionsAC(true));
      });
      dispatch(setFormColorThemeAC(editFormsData?.primaryColor));
      dispatch(setIsHidePoweredLabelAC(editFormsData?.hidePoweredLabel));
      dispatch(setIsHideLogoAC(editFormsData?.hideLogo))
    }
  }, [editFormsData]);

  useEffect(() => {
    if (editFormTemplateFlow) {
      getFormTemplateByIdApi(id).then((res) => {
        dispatch(setCompanyNameAC(res.data.data?.name));
        dispatch(setQuestionsAC(res.data.data?.formsData[0]?.questions));
        dispatch(
          setHSWCYOTitleAC(
            res.data.data?.formsData[0]?.howShallWeContactYou?.title
          )
        );
        dispatch(
          setEndingPageTextAC(res.data.data?.formsData[0]?.endingPageText)
        );
        res.data.data?.formsData[0]?.questions.map((el: any) => {
          el.answerOptions &&
            el.answerOptions[0] &&
            dispatch(setIsAnyAnswerOptionsAC(true));
        });
        setCurrentFormTemplateImg(res.data.data.formsData[0].logo);
      });
      dispatch(setFormColorThemeAC(editFormsData?.primaryColor));
      dispatch(setIsHidePoweredLabelAC(editFormsData?.hidePoweredLabel));
      dispatch(setIsHideLogoAC(editFormsData?.hideLogo))
    }
  }, [editFormTemplateFlow, id]);

  useEffect(() => {
    if (formTemplateId) {
      getFormTemplateByIdApi(formTemplateId).then((res) => {
        dispatch(setCompanyNameAC(res.data.data?.name));
        dispatch(setQuestionsAC(res.data.data?.formsData[0]?.questions));
        dispatch(
          setHSWCYOTitleAC(
            res.data.data?.formsData[0]?.howShallWeContactYou?.title
          )
        );
        dispatch(
          setEndingPageTextAC(res.data.data?.formsData[0].endingPageText)
        );
        res.data.data?.formsData[0]?.questions.map((el: any) => {
          el.answerOptions &&
            el.answerOptions[0] &&
            dispatch(setIsAnyAnswerOptionsAC(true));
        });
        setCurrentFormTemplateImg(res.data.data.formsData[0].logo);
      });
    }
  }, [formTemplateId]);

  useEffect(() => {
    if (!token) {
      navigate(ROUTES.HOME);
    }
  }, [token]);

  useEffect(() => {
    dispatch(setCompanyNameAC(""));
    dispatch(setIsHideLogoAC(false));
    dispatch(setCompanyLogoAC(null));
    dispatch(setCurrentCreateFormPageAC(0));
    dispatch(setCurrentFormPageAC(0));
    dispatch(setEndingPageTextAC("Thank you!"));
    dispatch(
      setQuestionsAC([
        {
          id: 0,
          question: "",
          answerOptions: null,
          conditionalAnswerOptions: null,
        },
      ])
    );
  }, [dispatch]);

  useEffect(() => {
    getCurrentUserPlanApi().then((res) => {
      setIsCanHidePoweredLabel(res.data.data.plan.canHidePoweredLabel);
    });
  }, []);

  return (
    <div className={styles.wrapper}>
      <Helmet>
        <title>LeadQuiz | Form builder</title>
      </Helmet>
      <Header />
      <div className={styles.progressBarOuterWrapper}>
        <div className={styles.progressBarWrapper}>
          <ProgressBar
            currentStep={currentIndex}
            totalFormPagesCount={content.length - 1}
          />
        </div>
      </div>
      {content[currentIndex].component}
      <Footer
        content={content}
        selectedFormTemplateId={selectedFormTemplateId}
      />
    </div>
  );
};

export default FormBuilder;
