import styles from "./thisIsYourTool.module.scss";
import arrowBottomImg from "src/assets/salesFunnel/arrowBottom.svg";
import ThisIsYourToolCard from "./thisIsYourToolCard/thisIsYourToolCard";
import facebookAndGoogleAds from "src/assets/salesFunnel/facebookAndGoogleAds.png";
import iPadMiniImg from "src/assets/salesFunnel/iPadMini.png";
import macStudioImg from "src/assets/salesFunnel/macStudio.png";
import simplifyYourLeadGenerationImg from "src/assets/salesFunnel/simplifyYourLeadGeneration .png";

const ThisIsYourTool = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <p className={styles.title}>
          THIS IS YOUR TOOL TO START GENERATING <span>QUALITY LEADS</span>
        </p>
        <p className={styles.subtitle}>
          LeadQuiz integrates seamlessly with Facebook Ads and Google Ads,
          helping you capture leads directly from these platforms. Whether
          promoting seasonal offers or core services, our customizable forms
          convert clicks into qualified leads, boosting your ad performance.
        </p>
      </div>
      <div className={styles.footer}>
        <img src={arrowBottomImg} alt="arrow down" />
        <p>More about us</p>
      </div>
      <div className={styles.content}>
        <ThisIsYourToolCard
          className={styles.item}
          title="Maximize Results from Facebook & Google Ads"
          subtitle="LeadQuiz integrates seamlessly with Facebook Ads and Google Ads,
            helping you capture leads directly from these platforms"
          img={facebookAndGoogleAds}
        />
        <ThisIsYourToolCard
          className={styles.item}
          title="Increase Your Leads Quality"
          subtitle="Our forms enhance lead quality by validating the information submitted, including contact details like phone numbers and emails."
          img={iPadMiniImg}
        />
        <ThisIsYourToolCard
          className={styles.item}
          title="Automate & Streamline Your Lead Management"
          subtitle="LeadQuiz integrates effortlessly with the CRMs you already use, like Jobber, HubSpot, and others"
          img={macStudioImg}
        />
        <ThisIsYourToolCard
          className={styles.item}
          title="Simplify Your Lead Generation "
          subtitle="LeadQuiz is built specifically to help businesses generate high-quality leads, especially through Facebook and Google Ads"
          img={simplifyYourLeadGenerationImg}
        />
      </div>
    </div>
  );
};

export default ThisIsYourTool;
