import { apiRoutes } from "src/_utils/api/api";
import styles from "./callBooking.module.scss";
import callIcon from "src/assets/call.svg";
import { useDispatch } from "react-redux";
import { setIsShowRegisterModalAC } from "src/store/actions";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";

const CallBooking: React.FC<any> = ({
  isUserChangingPlan,
  handleOnlyChangePlan,
  pricePerForm,
  priceId,
  id,
  formsCount,
  isTrial,
  isWithoutPlan,
}: any) => {
  const dispatch = useDispatch();

  const [isTrialUsed, setIsTrialUsed] = useState(false);

  const hostname = window.location.hostname;
  const cleanedHostname = hostname.startsWith("www.")
    ? hostname.slice(4)
    : hostname;
  const subdomain = cleanedHostname.split(".")[0];

  const handleGetPlan = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      const stripePromise = loadStripe(
        process.env.REACT_APP_PUBLISHABLE_KEY as string
      );

      const stripe = await stripePromise;

      let payload;

      if (subdomain === process.env.REACT_APP_AGENCY?.split(".")[0]) {
        if (pricePerForm) {
          payload = {
            planId: id,
            priceId,
            formsCount,
            isAgencyPage: true,
          };
        } else {
          payload = {
            planId: id,
            priceId,
            isAgencyPage: true,
          };
        }
      } else {
        if (pricePerForm) {
          payload = {
            planId: id,
            priceId,
            formsCount,
          };
        } else {
          payload = {
            planId: id,
            priceId,
          };
        }
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}${
            isTrial
              ? apiRoutes.stripeCreateTrialSession
              : apiRoutes.stripeCreateSession
          }`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          errorData.error.message ===
            "ERROR_USER_HAD_PLAN_OR_TRIAL_PREVIOUSLY" && setIsTrialUsed(true);
        }

        localStorage.setItem("purchaseSuccess", "true");

        const data = await response.json();

        const sessionId = data.data.sessionId;

        //@ts-ignore
        const { error } = await stripe?.redirectToCheckout({
          sessionId,
        });
      } catch (error: any) {
        console.log(error, "errorData");
      }
    }
  };

  const handleChangeToSelectedPlanAndBookCall = async () => {
    handleOnlyChangePlan();

    const token = localStorage.getItem("token");

    if (token) {
      const stripePromise = loadStripe(
        process.env.REACT_APP_PUBLISHABLE_KEY as string
      );

      const stripe = await stripePromise;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}${apiRoutes.createBuyCallSession}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      localStorage.setItem("purchaseSuccess", "true");

      const data = await response.json();

      const sessionId = data.data.sessionId;

      //@ts-ignore
      const { error } = await stripe?.redirectToCheckout({
        sessionId,
      });
    } else {
      dispatch(setIsShowRegisterModalAC(true));
    }
  };

  const handleGetSelectedPlanAndBookCall = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      const stripePromise = loadStripe(
        process.env.REACT_APP_PUBLISHABLE_KEY as string
      );

      const stripe = await stripePromise;

      let payload;

      if (subdomain === process.env.REACT_APP_AGENCY?.split(".")[0]) {
        if (pricePerForm) {
          payload = {
            planId: id,
            priceId,
            formsCount,
            purchaseOnboarding: true,
            isAgencyPage: true,
          };
        } else {
          payload = {
            planId: id,
            priceId,
            purchaseOnboarding: true,
            isAgencyPage: true,
          };
        }
      } else {
        if (pricePerForm) {
          payload = {
            planId: id,
            priceId,
            formsCount,
            purchaseOnboarding: true,
          };
        } else {
          payload = {
            planId: id,
            priceId,
            purchaseOnboarding: true,
          };
        }
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}${
            isTrial
              ? apiRoutes.stripeCreateTrialSession
              : apiRoutes.stripeCreateSession
          }`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          errorData.error.message ===
            "ERROR_USER_HAD_PLAN_OR_TRIAL_PREVIOUSLY" && setIsTrialUsed(true);
        }

        localStorage.setItem("purchaseSuccess", "true");

        const data = await response.json();

        const sessionId = data.data.sessionId;

        //@ts-ignore
        const { error } = await stripe?.redirectToCheckout({
          sessionId,
        });
      } catch (error: any) {
        console.log(error, "errorData");
      }
    } else {
      dispatch(setIsShowRegisterModalAC(true));
    }
  };

  const onlyGetOnboardingSetup = async () => {
    const token = localStorage.getItem("token");

    if (token) {
      const stripePromise = loadStripe(
        process.env.REACT_APP_PUBLISHABLE_KEY as string
      );

      const stripe = await stripePromise;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}${apiRoutes.createBuyCallSession}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      localStorage.setItem("purchaseSuccess", "true");

      const data = await response.json();

      const sessionId = data.data.sessionId;

      //@ts-ignore
      const { error } = await stripe?.redirectToCheckout({
        sessionId,
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <img src={callIcon} alt="call" />
      <p className={styles.title}>
        Get the Most Out of LeadQuiz with Expert Onboarding!
      </p>
      <p className={styles.headerLabel}>One Time Fee of $49</p>
      <p className={styles.subtitle}>
        Unlock the full potential of your LeadQuiz subscription by booking a 1:1
        onboarding call.
        <br />
        <br />
        Our experts will help you:
      </p>
      <ul className={styles.list}>
        <li>Set up your account for success</li>
        <li>Create customized forms that convert</li>
        <li>Seamlessly integrate with your CRM</li>
      </ul>
      {isTrialUsed && (
        <p className={styles.errorMessage}>
          You have already used the free trial or have active plan
        </p>
      )}
      {isUserChangingPlan ? (
        <div className={styles.buttonsWrap}>
          <button onClick={handleChangeToSelectedPlanAndBookCall}>
            Yes, Upgrade My Plan with Expert Onboarding
          </button>
          <button onClick={handleOnlyChangePlan}>
            Continue Without Onboarding
          </button>
        </div>
      ) : (
        <div className={styles.buttonsWrap}>
          {isWithoutPlan ? (
            <button onClick={onlyGetOnboardingSetup}>
              Upgrade My Plan with Expert Onboarding
            </button>
          ) : (
            <button onClick={handleGetSelectedPlanAndBookCall}>
              Yes, Upgrade My Plan with Expert Onboarding
            </button>
          )}
          {!isWithoutPlan && (
            <button onClick={handleGetPlan}>Continue Without Onboarding</button>
          )}
        </div>
      )}
    </div>
  );
};

export default CallBooking;
