import React from "react";
import styles from "./formInput.module.scss";
import { IInput } from "../interfaces/interfaces";

const FormInput: React.FC<IInput> = ({
  placeholder,
  value,
  onChange,
  type = "text",
  maxLength,
  label,
  onClick,
  className,
  disabled,
  color,
}: IInput) => {
  return (
    <div onClick={onClick} className={styles.wrapper}>
      {label && value && value !== "" && (
        <p className={styles.inputLabel}>{label}</p>
      )}
      <input
        value={value}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
        className={className}
        disabled={disabled}
        style={{ borderColor: color }}
      />
    </div>
  );
};

export default FormInput;
