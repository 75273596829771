import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Benefits from "../homePage/benefits/benefits";
import Footer from "../homePage/footer/footer";
import Header from "../homePage/header/header";
import Integrates from "../homePage/integrates/integrates";
import styles from "./howItWorks.module.scss";
import Top from "../top/top";
import ActionContentFooter from "../forAgencies/actionContentFooter/actionContentFooter";
import HowItWorksContent from "./howItWorksContent/howItWorksContent";

function HowItWorks() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.outerWrapper}>
      <Helmet>
        <title>LeadQuiz | How it works</title>
        <meta
          name="description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <link rel="canonical" href="https://leadquiz.com/how-it-works" />
        <meta property="og:title" content="LeadQuiz | How it works" />
        <meta
          property="og:description"
          content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://leadquiz.com/how-it-works" />
        <meta property="og:image" content="src/assets/homePage/devices.png" />
        <meta
          name="keywords"
          content="LeadQuiz, form builder, create form, generate form, Lead Quiz"
        />
      </Helmet>
      <Header />
      <Top />
      <div className={styles.wrapper}>
        <Integrates />
        <Benefits />
        <HowItWorksContent />
        <ActionContentFooter
          title="Start Growing Your Business with LeadQuiz"
          subtitle="Ready to transform your lead generation process and get more from your Facebook and Google Ads? <b>Sign up today</b> and start attracting, engaging, and converting more leads for your business."
        />
        <Footer />
      </div>
    </div>
  );
}

export default HowItWorks;
