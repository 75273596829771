import { IQuestion } from "src/pages/formBuilder/interfaces/interfaces";
import { IUserInfo } from "src/pages/formView/interfaces/interfaces";

export const SET_COMPANY_NAME = "SET_COMPANY_NAME";
export const SET_COMPANY_LOGO = "SET_COMPANY_LOGO";
export const CURRENT_CREATE_FORM_PAGE = "CURRENT_CREATE_FORM_PAGE";
export const SET_QUESTIONS = "SET_QUESTIONS";
export const CURRENT_FORM_PAGE = "CURRENT_FORM_PAGE";
export const SET_USER_ANSWERS = "SET_USER_ANSWERS";
export const ADD_ANSWER_OPTION = "ADD_ANSWER_OPTION";
export const UPDATE_ANSWER_OPTION_TEXT = "UPDATE_ANSWER_OPTION_TEXT";
export const SET_CONTINUE_BUTTON_DISABLED = "SET_CONTINUE_BUTTON_DISABLED";
export const SET_IS_ANY_ANSWER_OPTIONS = "SET_IS_ANY_ANSWER_OPTIONS";
export const UPDATE_ANSWER_OPTION_ICON = "UPDATE_ANSWER_OPTION_ICON";
export const SET_IS_GOOGLE_AUTH_ERROR = "SET_IS_GOOGLE_AUTH_ERROR";
export const EDIT_TEXT_ANSWER_OPTION_TO_QUESTION =
  "EDIT_TEXT_ANSWER_OPTION_TO_QUESTION";
export const EDIT_ICON_ANSWER_OPTION_TO_QUESTION =
  "EDIT_ICON_ANSWER_OPTION_TO_QUESTION";
export const SET_ENDING_PAGE_TEXT = "SET_ENDING_PAGE_TEXT";
export const SET_USER_INFO = "SET_USER_INFO";
export const CLEAR_USER_ANSWERS = "CLEAR_USER_ANSWERS";
export const SET_USER_NAME = "SET_USER_NAME";
export const SET_HOW_SHALL_WE_CONTACT_YOU_ERROR =
  "SET_HOW_SHALL_WE_CONTACT_YOU_ERROR";
export const OBSERVE_NEXT_PLAN_CHANGED = "OBSERVE_NEXT_PLAN_CHANGED";
export const OBSERVE_ADDITIONAL_PURCHASE_ADDED =
  "OBSERVE_ADDITIONAL_PURCHASE_ADDED";
export const SET_IS_USER_PLAN_ACTIVE = "SET_IS_USER_PLAN_ACTIVE";
export const SET_CURRENT_USER_PLAN = "SET_CURRENT_USER_PLAN";
export const SET_IS_CURRENT_USER_PLAN_LOADING =
  "SET_IS_CURRENT_USER_PLAN_LOADING";
export const SET_IS_SHOW_PRICING_MODAL = "SET_IS_SHOW_PRICING_MODAL";
export const SET_IS_SHOW_LOGIN_MODAL = "SET_IS_SHOW_LOGIN_MODAL";
export const SET_IS_SHOW_REGISTER_MODAL = "SET_IS_SHOW_REGISTER_MODAL";
export const SET_IS_SHOW_CALENDLY_MODAL = "SET_IS_SHOW_CALENDLY_MODAL";
export const SET_HSWCY_OTHER_INFO = "SET_HSWCY_OTHER_INFO";
export const SET_HSWCY_TITLE = "SET_HSWCY_TITLE";
export const ADD_ANSWER_TYPE_TO_QUESTION = "ADD_ANSWER_TYPE_TO_QUESTION";
export const ADD_ANSWER_TYPE_TO_ANSWER_OPTION =
  "ADD_ANSWER_TYPE_TO_ANSWER_OPTION";
export const SET_IS_MULTIPLE_SELECT_ANSWER = "SET_IS_MULTIPLE_SELECT_ANSWER";
export const SET_IS_SHOW_EDIT_FORM_MODAL = "SET_IS_SHOW_EDIT_FORM_MODAL";
export const SET_SELECTED_FORM_TEMPLATE_ID = "SET_SELECTED_FORM_TEMPLATE_ID";
export const SET_SELECTED_QUESION_SHOW_LOGIC =
  "SET_SELECTED_QUESION_SHOW_LOGIC";
export const SET_CONDITIONAL_ANSWER_OPTIONS_FOR_QUESTION =
  "SET_CONDITIONAL_ANSWER_OPTIONS_FOR_QUESTION";
export const SET_ALL_ANSWERS = "SET_ALL_ANSWERS";
export const REMOVE_USER_CONDITIONAL_ANSWERS =
  "REMOVE_USER_CONDITIONAL_ANSWERS";
export const SET_IS_BACK_PRESSED = "SET_IS_BACK_PRESSED";
export const REMOVE_LAST_USER_ANSWERS = "REMOVE_LAST_USER_ANSWERS";
export const SET_FORM_COLOR_THEME = "SET_FORM_COLOR_THEME";
export const SET_IS_HIDE_POWERED_LABEL = "SET_IS_HIDE_POWERED_LABEL";
export const SET_IS_HIDE_LOGO = "SET_IS_HIDE_LOGO";

export const setCompanyNameAC = (value: any) => ({
  type: SET_COMPANY_NAME,
  payload: value,
});

export const setCompanyLogoAC = (value: any) => ({
  type: SET_COMPANY_LOGO,
  payload: value,
});

export const setCurrentCreateFormPageAC = (value: number) => ({
  type: CURRENT_CREATE_FORM_PAGE,
  payload: value,
});

export const setCurrentFormPageAC = (value: number) => ({
  type: CURRENT_FORM_PAGE,
  payload: value,
});

export const setQuestionsAC = (payload: IQuestion[]) => ({
  type: SET_QUESTIONS,
  payload,
});

export const setUserAnswersAC = (answer: any, index: number) => ({
  type: SET_USER_ANSWERS,
  payload: { answer, index },
});

export const removeLastUserAnswerAC = (index: number) => ({
  type: REMOVE_LAST_USER_ANSWERS,
  payload: index,
});

export const removeUserConditionalAnswerAC = (value: number) => ({
  type: REMOVE_USER_CONDITIONAL_ANSWERS,
  payload: value,
});

export const clearUserAnswersAC = () => ({
  type: CLEAR_USER_ANSWERS,
  payload: [],
});

export const addAnswerOptionAC = (
  questionIndex: any,
  answerText: any,
  answerOptionId: string
) => ({
  type: ADD_ANSWER_OPTION,
  payload: { questionIndex, answerText, answerOptionId },
});

export const updateAnswerOptionTextAC = (
  questionIndex: any,
  optionIndex: any,
  newText: any
) => ({
  type: UPDATE_ANSWER_OPTION_TEXT,
  payload: { questionIndex, optionIndex, newText },
});

export const updateAnswerOptionIconAC = (
  questionIndex: number,
  optionIndex: number,
  newIcon: string,
  iconFile?: any
) => ({
  type: UPDATE_ANSWER_OPTION_ICON,
  payload: { questionIndex, optionIndex, newIcon, iconFile },
});

export const setContinueButtonDisabledAC = (value: boolean) => ({
  type: SET_CONTINUE_BUTTON_DISABLED,
  payload: value,
});

export const setIsAnyAnswerOptionsAC = (value: boolean) => ({
  type: SET_IS_ANY_ANSWER_OPTIONS,
  payload: value,
});

export const setGoogleAuthErrorAC = (value: boolean) => ({
  type: SET_IS_GOOGLE_AUTH_ERROR,
  payload: value,
});

export const addAnswerTypeToQuestionAC = (
  questionIndex: any,
  answerType: any
) => ({
  type: ADD_ANSWER_TYPE_TO_QUESTION,
  payload: { questionIndex, answerType },
});

export const setIsMultipleSelectAnswerAC = (
  questionIndex: any,
  isMultipleSelect: any
) => ({
  type: SET_IS_MULTIPLE_SELECT_ANSWER,
  payload: { questionIndex, isMultipleSelect },
});

export const editTextAnswerOptionToQuestionAC = (
  questionIndex: number,
  answerOptionIndex: number,
  questionArrayIndex: number,
  optionIndex: number,
  title: string
) => {
  return {
    type: EDIT_TEXT_ANSWER_OPTION_TO_QUESTION,
    payload: {
      questionIndex,
      answerOptionIndex,
      questionArrayIndex,
      optionIndex,
      title,
    },
  };
};

export const editIconAnswerOptionToQuestionAC = (
  questionIndex: number,
  answerOptionIndex: number,
  questionArrayIndex: number,
  optionIndex: number,
  icon: any,
  iconFile?: any
) => {
  return {
    type: EDIT_ICON_ANSWER_OPTION_TO_QUESTION,
    payload: {
      questionIndex,
      answerOptionIndex,
      questionArrayIndex,
      optionIndex,
      icon,
      iconFile,
    },
  };
};

export const setEndingPageTextAC = (value: string) => {
  return {
    type: SET_ENDING_PAGE_TEXT,
    payload: value,
  };
};

export const setUserInfoAC = (value: IUserInfo) => {
  return {
    type: SET_USER_INFO,
    payload: value,
  };
};

export const setUserNameAC = (value: string) => {
  return {
    type: SET_USER_NAME,
    payload: value,
  };
};

export const setHowShallWeContactYouErrorMessageAC = (value: string) => {
  return {
    type: SET_HOW_SHALL_WE_CONTACT_YOU_ERROR,
    payload: value,
  };
};

export const observeNextPlanChangedAC = (value: boolean) => {
  return {
    type: OBSERVE_NEXT_PLAN_CHANGED,
    payload: value,
  };
};

export const observeAdditionalPurchaseAddedAC = (value: boolean) => {
  return {
    type: OBSERVE_ADDITIONAL_PURCHASE_ADDED,
    payload: value,
  };
};

export const setIsUserPlanActiveAC = (value: boolean) => {
  return {
    type: SET_IS_USER_PLAN_ACTIVE,
    payload: value,
  };
};

export const setCurrentUserPlanAC = (value: boolean) => {
  return {
    type: SET_CURRENT_USER_PLAN,
    payload: value,
  };
};

export const setIsCurrentUserPlanLoadingAC = (value: boolean) => {
  return {
    type: SET_IS_CURRENT_USER_PLAN_LOADING,
    payload: value,
  };
};

export const setIsShowPricingModalAC = (value: boolean) => {
  return {
    type: SET_IS_SHOW_PRICING_MODAL,
    payload: value,
  };
};

export const setIsShowLoginModalAC = (value: boolean) => {
  return {
    type: SET_IS_SHOW_LOGIN_MODAL,
    payload: value,
  };
};

export const setIsShowRegisterModalAC = (value: boolean) => {
  return {
    type: SET_IS_SHOW_REGISTER_MODAL,
    payload: value,
  };
};

export const setIsShowCalendlyModalAC = (value: {
  show: boolean;
  url: string | undefined;
}) => {
  return {
    type: SET_IS_SHOW_CALENDLY_MODAL,
    payload: value,
  };
};

export const setHSWCYOtherInfoAC = (value: any) => {
  return {
    type: SET_HSWCY_OTHER_INFO,
    payload: value,
  };
};

export const setHSWCYOTitleAC = (value: any) => {
  return {
    type: SET_HSWCY_TITLE,
    payload: value,
  };
};

export const setIsShowEditFormModalAC = (value: any) => {
  return {
    type: SET_IS_SHOW_EDIT_FORM_MODAL,
    payload: value,
  };
};

export const setSelectedFormTemplateIdAC = (value: string) => {
  return {
    type: SET_SELECTED_FORM_TEMPLATE_ID,
    payload: value,
  };
};

export const setConditionalAnswerOptionsForQuestionAC = (
  questionId: number,
  value: any,
  isHide: boolean
) => {
  return {
    type: SET_CONDITIONAL_ANSWER_OPTIONS_FOR_QUESTION,
    payload: {
      value: value,
      questionId: questionId,
      isHide: isHide,
    },
  };
};

export const setAllAnswersAC = (value: any) => {
  return {
    type: SET_ALL_ANSWERS,
    payload: value,
  };
};

export const setIsBackPressedAC = (value: any) => {
  return {
    type: SET_IS_BACK_PRESSED,
    payload: value,
  };
};

export const setFormColorThemeAC = (value: any) => {
  return {
    type: SET_FORM_COLOR_THEME,
    payload: value,
  };
};

export const setIsHidePoweredLabelAC = (value: any) => {
  return {
    type: SET_IS_HIDE_POWERED_LABEL,
    payload: value,
  };
};

export const setIsHideLogoAC = (value: any) => {
  return {
    type: SET_IS_HIDE_LOGO,
    payload: value,
  };
};
