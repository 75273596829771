import { useEffect } from "react";
import styles from "./../salesFunnel/salesFunnel.module.scss";
import { Helmet } from "react-helmet";
import Integrates from "../homePage/integrates/integrates";
import HowToMakeYourQuiz from "../homePage/howToMakeYourQuiz/howToMakeYourQuiz";
import Benefits from "../homePage/benefits/benefits";
import CalendlyModal from "../homePage/payments/registerModal/calendlyModal";
import RegisterModal from "../homePage/header/registerModal/registerModal";
import ActionContentFooter from "../forAgencies/actionContentFooter/actionContentFooter";
import LoginModal from "../homePage/header/loginModal/loginModal";
import Footer from "../homePage/footer/footer";
import SalesFunnelTop from "../salesFunnel/salesFunnelTop/salesFunnelTop";
import ThisIsYourTool from "../salesFunnel/thisIsYourTool/thisIsYourTool";
import SalesFunnelPricing from "../salesFunnel/salesFunnelPricing/salesFunnelPricing";
import AUTH_TYPES from "src/_utils/authTypes";
import PricingModal from "../homePage/header/pricingModal/pricingModal";

const Agency = () => {
  const hostname = window.location.hostname;
  const cleanedHostname = hostname.startsWith("www.")
    ? hostname.slice(4)
    : hostname;
  const subdomain = cleanedHostname.split(".")[0];

  useEffect(() => {
    if (
      !document.getElementById("facebook-pixel-script-agency") &&
      subdomain === process.env.REACT_APP_AGENCY?.split(".")[0]
    ) {
      const script = document.createElement("script");
      script.id = "facebook-pixel-script-agency";
      script.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '912896493628263');
        fbq('track', 'PageView');
      `;
      document.head.appendChild(script);

      const noscript = document.createElement("noscript");
      noscript.id = "facebook-pixel-noscript-agency";
      noscript.innerHTML = `<img height="1" width="1" style="display:none"
           src="https://www.facebook.com/tr?id=912896493628263&ev=PageView&noscript=1" />`;
      document.body.appendChild(noscript);

      return () => {
        document.getElementById("facebook-pixel-script-agency")?.remove();
        document.getElementById("facebook-pixel-noscript-agency")?.remove();
      };
    }
  }, [subdomain]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://leadquiz.com/embed.js';
    script.setAttribute('subdomain', 'epoxy');
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className={styles.outerWrapper}>
        <Helmet>
          <title>LeadQuiz | Agency</title>
          <meta
            name="description"
            content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
          />
          <link rel="canonical" href="https://agency.leadquiz.com" />
          <meta property="og:title" content="LeadQuiz | Agency" />
          <meta
            property="og:description"
            content="Replace your Facebook forms, and confusing websites with an interactive form. Allowing your business to increase lead quality and convert more clients."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://agency.leadquiz.com" />
          <meta property="og:image" content="src/assets/homePage/devices.png" />
          <meta
            name="keywords"
            content="LeadQuiz, form builder, create form, generate form"
          />
          <meta
            name="keywords"
            content="LeadQuiz, form builder, create form, generate form, Lead Quiz"
          />
        </Helmet>
        <SalesFunnelTop
          title="Start Your <span>14-Day Free Trial</span> Today!"
          subtitle="<span>LeadQuiz</span> is designed to help agencies streamline lead generation for their clients. Create custom, <span>high-performing</span> forms to maximize results and <span>drive client success</span> across every campaign"
        />
        <div className={styles.wrapper}>
          <div className={styles.integrates}>
            <Integrates />
          </div>
          <div className={styles.thisIsYourTool}>
            <ThisIsYourTool />
          </div>
          <div className={styles.benefits}>
            <Benefits title="Why <span>LeadQuiz</span>" />
          </div>
          <div className={styles.howItWorks}>
            <HowToMakeYourQuiz
              title="The most interesting thing is <span>how it works</span>"
              subtitle="It is easy and quick. Just 3 steps"
              isSalesFunnel
            />
          </div>
          <SalesFunnelPricing />
          <div className={styles.actionContentFooter}>
            <ActionContentFooter
              title="Drive Qualified Leads Into Your Clients Pipeline With LeadQuiz"
              subtitle="Ready to transform your lead generation process and get more from your Facebook and Google Ads? <b>Sign up today</b> and start attracting, engaging, and converting more leads for your business."
            />
          </div>
          <Footer isTrial />
        </div>
      </div>
      <CalendlyModal />
      <PricingModal isTrial />
      <LoginModal type={AUTH_TYPES.AGENCY} />
      <RegisterModal type={AUTH_TYPES.AGENCY} />
    </>
  );
};

export default Agency;
