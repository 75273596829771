import React from "react";
import styles from "./chooseTheme.module.scss";
import FormInput from "src/_elements/formInput/formInput";
import TextArea from "src/_elements/textArea/textArea";
import ProgressBar from "src/_elements/progressBar/progressBar";
import { useDispatch, useSelector } from "react-redux";
import { setFormColorThemeAC } from "src/store/actions";

const ChooseTheme = () => {
  const dispatch = useDispatch();

  const formColorTheme = useSelector((state: any) => state.formColorTheme);

  const handleChange = (e: any) => {
    dispatch(setFormColorThemeAC(e.target.value));
  };

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <h1>Set color theme</h1>
        <p>Select a color theme for your form</p>
        <div className={styles.content}>
          <input
            type="color"
            value={formColorTheme}
            onChange={handleChange}
            className={styles.colorPicker}
          />
          <p>
            Current color: <strong>{formColorTheme}</strong>
          </p>
          <FormInput color={formColorTheme} value="Example text input" />
          <TextArea color={formColorTheme} value="Example text input" />
          <ProgressBar
            color={formColorTheme}
            currentStep={3}
            totalFormPagesCount={5}
          />
        </div>
      </div>
    </div>
  );
};

export default ChooseTheme;
