import styles from "./benefits.module.scss";
import improvedLeadQuality from "src/assets/homePage/improvedLeadQuality.png";
import reducedSalesCycle from "src/assets/homePage/reducedSalesCycle.png";
import tailoredExperience from "src/assets/homePage/tailoredExperience.png";
import higherConversionRate from "src/assets/homePage/higherConversionRate.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Benefits: React.FC<any> = ({ title = "Benefits of LeadQuiz" }) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const benefitsList = [
    {
      img: improvedLeadQuality,
      text: "Improved Lead Quality",
    },
    {
      img: reducedSalesCycle,
      text: "Reduced Sales Cycle",
    },
    {
      img: tailoredExperience,
      text: "Tailored Experience",
    },
    {
      img: higherConversionRate,
      text: "Higher Conversion Rate",
    },
  ];

  return (
    <div className={styles.wrapper}>
      <h3 dangerouslySetInnerHTML={{ __html: title }} />
      <div className={styles.benefitsWrapper}>
        <div className={styles.benefitItem}>
          <img src={improvedLeadQuality} alt="Improved Lead Quality" />
          <p>Improved Lead Quality</p>
        </div>
        <div className={styles.benefitItem}>
          <img src={reducedSalesCycle} alt="Reduced Sales Cycle" />
          <p>Reduced Sales Cycle</p>
        </div>
        <div className={styles.benefitItem}>
          <img src={tailoredExperience} alt="Tailored Experience" />
          <p>Tailored Experience</p>
        </div>
        <div className={styles.benefitItem}>
          <img src={higherConversionRate} alt="Higher Conversion Rate" />
          <p>Higher Conversion Rate</p>
        </div>
      </div>
      <div className={styles.benefitsWrapperSlider}>
        <Slider {...sliderSettings}>
          {benefitsList.map((el, key) => (
            <div key={key} className={styles.benefitItem}>
              <img src={el.img} alt={el.text} />
              <p>{el.text}</p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Benefits;
