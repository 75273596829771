import React, { useEffect } from "react";
import styles from "./chooseIsShowPowered.module.scss";
import ToggleSwitch from "src/_elements/toggleSwitch/toggleSwitch";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentCreateFormPageAC,
  setIsHidePoweredLabelAC,
} from "src/store/actions";

const ChooseIsShowPowered: React.FC<any> = ({ isCanHidePoweredLabel }: any) => {
  const dispatch = useDispatch();

  const currentIndex = useSelector((state: any) => state.currentCreateFormPage);
  const isHidePoweredLabel = useSelector(
    (state: any) => state.isHidePoweredLabel
  );

  useEffect(() => {
    if (!isCanHidePoweredLabel) {
      dispatch(setCurrentCreateFormPageAC(currentIndex + 1));
    }
  }, [isCanHidePoweredLabel]);

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <h1>Hide "Powered by LeadQuiz"</h1>
        <p>Do you want to hide "Powered by LeadQuiz" message in footer?</p>
        <div className={styles.content}>
          <ToggleSwitch
            toggleChange={() => {
              dispatch(setIsHidePoweredLabelAC(!isHidePoweredLabel));
            }}
            value={isHidePoweredLabel}
          />
        </div>
      </div>
    </div>
  );
};

export default ChooseIsShowPowered;
