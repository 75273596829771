import { jwtDecode } from "jwt-decode";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ROUTES from "./_utils/routes/routes";
import styles from "./App.module.scss";
import AdminPage from "./pages/adminPage/adminPage";
import EditFormTemplatePage from "./pages/adminPage/formTemplates/formTemplateItem/editFormTemplatePage/editFormTemplatePage";
import GoogleLogin from "./pages/auth/googleLogin/googleLogin";
import LogIn from "./pages/auth/login/login";
import ActivateUserPage from "./pages/auth/signup/activateUserPage/activateUserPage";
import SignUp from "./pages/auth/signup/signup";
import ChangeEmail from "./pages/changeEmail/changeEmail";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import ResetPassword from "./pages/forgotPassword/resetPassword/resetPassword";
import FormBuilder from "./pages/formBuilder/formBuilder";
import CreateFormTemplate from "./pages/forms/createFormTemplate/createFormTemplate";
import EditForm from "./pages/forms/editForm/editForm";
import Forms from "./pages/forms/forms";
import FormView from "./pages/formView/formView";
import HomePage from "./pages/homePage/homePage";
import HowItWorks from "./pages/howItWorks/howItWorks";
import LeadsPage from "./pages/leads/leadsPage";
import PaymentSuccess from "./pages/paymentSuccess/paymentSuccess";
import Pricing from "./pages/pricing/pricing";
import PrivacyPolicy from "./pages/privacy-policy/privacy-policy";
import SettingsPage from "./pages/settingPage/settingsPage";
import TermsOfUse from "./pages/terms-of-use/terms-of-use";
import TradePage from "./pages/whoWeServe/tradePage/tradePage";
import WhoWeServe from "./pages/whoWeServe/whoWeServe";
import ForAgencies from "./pages/forAgencies/forAgencies";

import React, { useEffect } from "react";
import Intercom from "@intercom/messenger-js-sdk";
import { getCurrentUserInfoApi } from "./_utils/api/api";
import ThankYouPage from "./pages/formView/thankYouPage/thankYouPage";
import Support from "./pages/support/support";
import HowToCreateAForm from "./pages/support/howToCreateAForm/howToCreateAForm";
import HowToAddPrivacyPolicyAndBusinessInfo from "./pages/support/howToAddPrivacyPolicyAndBusinessInfo/howToAddPrivacyPolicyAndBusinessInfo";
import HowToIntegrateJobber from "./pages/support/howToIntegrateJobber/howToIntegrateJobber";
import HowToIntegrateWorkiz from "./pages/support/howToIntegrateWorkiz/howToIntegrateWorkiz";
import HowToIntegrateLeadConnector from "./pages/support/howToIntegrateLeadConnector/howToIntegrateLeadConnector";
import HowToIntegrateBuilderPrime from "./pages/support/howToIntegrateBuilderPrime/howToIntegrateBuilderPrime";
import HowToTrackFacebookAd from "./pages/support/howToTrackFacebookAd/howToTrackFacebookAd";
import HowToTrackGoogleAds from "./pages/support/howToTrackGoogleAds/howToTrackGoogleAds";
import SalesFunnel from "./pages/salesFunnel/salesFunnel";
import Agency from "./pages/agency/agency";
import ProOnboardingSetup from "./pages/proOnboardingSetup/proOnboardingSetup";

function App() {
  const location = useLocation();

  const getToken = () => localStorage.getItem("token");

  const hostname = window.location.hostname;
  const cleanedHostname = hostname.startsWith("www.")
    ? hostname.slice(4)
    : hostname;
  const subdomain = cleanedHostname.split(".")[0];

  useEffect(() => {
    if (
      !document.getElementById("own-facebook-pixel") &&
      subdomain === process.env.REACT_APP_DOMAIN?.split(".")[0] &&
      location.pathname !== "/success"
    ) {
      const script = document.createElement("script");
      script.id = "own-facebook-pixel";
      script.innerHTML = `
        !(function(f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '912896493628263');
        fbq('track', 'PageView');
      `;
      document.head.appendChild(script);

      const noscript = document.createElement("noscript");
      noscript.id = "own-facebook-pixel-noscript";
      noscript.innerHTML = `<img height="1" width="1" style="display:none"
           src="https://www.facebook.com/tr?id=912896493628263&ev=PageView&noscript=1" />`;
      document.body.appendChild(noscript);

      return () => {
        document.getElementById("own-facebook-pixel")?.remove();
        document.getElementById("own-facebook-pixel-noscript")?.remove();
      };
    }
  }, [subdomain, location]);

  if (getToken()) {
    const decodedToken = jwtDecode(getToken() as string);
    //@ts-ignore
    const expirationDate = decodedToken.exp * 1000;

    if (Date.now() >= expirationDate) {
      localStorage.removeItem("token");
    }
  }

  useEffect(() => {
    if (
      subdomain === process.env.REACT_APP_DOMAIN?.split(".")[0] ||
      subdomain === process.env.REACT_APP_TRY?.split(".")[0] ||
      subdomain === process.env.REACT_APP_AGENCY?.split(".")[0]
    ) {
      if (getToken()) {
        getCurrentUserInfoApi().then((res) => {
          Intercom({
            app_id: process.env.REACT_APP_INTERCOM_ID as string,
            user_id: res.data.data.id,
            name: res.data.data.name,
            email: res.data.data.email,
            created_at: res.data.data.createdAt,
          });
        });
      } else {
        Intercom({
          app_id: process.env.REACT_APP_INTERCOM_ID as string,
        });
      }
    }
  }, [getToken()]);

  if (subdomain === process.env.REACT_APP_DOMAIN?.split(".")[0]) {
    return (
      <div className={styles.wrapper}>
        <Routes>
          <Route path={ROUTES.HOME} element={<HomePage />} />

          <Route path={ROUTES.HOW_IT_WORKS} element={<HowItWorks />} />
          <Route path={ROUTES.WHO_WE_SERVE} element={<WhoWeServe />} />
          <Route path={ROUTES.PRICING} element={<Pricing />} />

          <Route path={ROUTES.FORM_BUILDER} element={<FormBuilder />} />
          <Route path={ROUTES.FORMS} element={<Forms />} />

          <Route path={ROUTES.SIGNUP} element={<SignUp />} />
          <Route path={ROUTES.LOGIN} element={<LogIn />} />

          <Route path={ROUTES.ACTIVATE_USER} element={<ActivateUserPage />} />

          <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />

          <Route path={ROUTES.CHANGE_EMAIL} element={<ChangeEmail />} />

          <Route path={ROUTES.GOOGLE_AUTH} element={<GoogleLogin />} />

          <Route path={ROUTES.ADMIN_PAGE} element={<AdminPage />} />

          <Route path={ROUTES.FOR_AGENCIES} element={<ForAgencies />} />

          <Route path={ROUTES.SETTINGS} element={<SettingsPage />} />

          <Route path={ROUTES.LEADS} element={<LeadsPage />} />

          <Route path={`${ROUTES.FORMS}/:id`} element={<FormView />} />

          <Route path={`${ROUTES.EDIT_FORM}/:id`} element={<EditForm />} />
          <Route
            path={`${ROUTES.CREATE_FORM_TEMPLTE}`}
            element={<CreateFormTemplate />}
          />
          <Route
            path={`${ROUTES.EDIT_FORM_TEMPLTE}/:id`}
            element={<EditFormTemplatePage />}
          />

          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.GENERAL_CONTRACTING}`}
            element={
              <TradePage
                tradeName="General Contracting"
                url={ROUTES.GENERAL_CONTRACTING}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.HVAC}`}
            element={
              <TradePage
                tradeName="HVAC (Heating, Ventilation, and Air Conditioning)"
                url={ROUTES.HVAC}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.ROOFING}`}
            element={<TradePage tradeName="Roofing" url={ROUTES.ROOFING} />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.SOLAR}`}
            element={<TradePage tradeName="Solar" url={ROUTES.SOLAR} />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.PLUMBING}`}
            element={<TradePage tradeName="Plumbing" url={ROUTES.PLUMBING} />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.ELECTRICAL}`}
            element={
              <TradePage tradeName="Electrical" url={ROUTES.ELECTRICAL} />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.PAINTING}`}
            element={<TradePage tradeName="Painting" url={ROUTES.PAINTING} />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.PEST_CONTROL}`}
            element={
              <TradePage tradeName="Pest Control" url={ROUTES.PEST_CONTROL} />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.LANDSCAPING_AND_LAWN_CARE}`}
            element={
              <TradePage
                tradeName="Landscaping & Lawn Care"
                url={ROUTES.LANDSCAPING_AND_LAWN_CARE}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.POOL_CONSTRUCTION}`}
            element={
              <TradePage
                tradeName="Pool Construction"
                url={ROUTES.POOL_CONSTRUCTION}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.CLEANING_SERVICES}`}
            element={
              <TradePage
                tradeName="Cleaning Services"
                url={ROUTES.CLEANING_SERVICES}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.EPOXY_COATING}`}
            element={
              <TradePage tradeName="Epoxy Coating" url={ROUTES.EPOXY_COATING} />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.FLOORING}`}
            element={<TradePage tradeName="Flooring" url={ROUTES.FLOORING} />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.HOME_RENOVATION}`}
            element={
              <TradePage
                tradeName="Home Renovation"
                url={ROUTES.HOME_RENOVATION}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.GARAGE_DOOR_SERVICES}`}
            element={
              <TradePage
                tradeName="Garage Door Services"
                url={ROUTES.GARAGE_DOOR_SERVICES}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.HOLIDAY_LIGHT_INSTALLATION}`}
            element={
              <TradePage
                tradeName="Holiday Light Installation"
                url={ROUTES.HOLIDAY_LIGHT_INSTALLATION}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.WINDOW_CLEANING}`}
            element={
              <TradePage
                tradeName="Window Cleaning"
                url={ROUTES.WINDOW_CLEANING}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.HOME_SECURITY}`}
            element={
              <TradePage tradeName="Home Security" url={ROUTES.HOME_SECURITY} />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.APPLIANCE_REPAIR}`}
            element={
              <TradePage
                tradeName="Appliance Repair"
                url={ROUTES.APPLIANCE_REPAIR}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.INTERIOR_DESIGN}`}
            element={
              <TradePage
                tradeName="Interior Design"
                url={ROUTES.INTERIOR_DESIGN}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.FURNITURE_ASSEMBLY}`}
            element={
              <TradePage
                tradeName="Furniture Assembly"
                url={ROUTES.FURNITURE_ASSEMBLY}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.HOME_ORGANIZATION}`}
            element={
              <TradePage
                tradeName="Home Organization"
                url={ROUTES.HOME_ORGANIZATION}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.MASONRY}`}
            element={<TradePage tradeName="Masonry" url={ROUTES.MASONRY} />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.DECK_AND_PATIO_CONSTRUCTION}`}
            element={
              <TradePage
                tradeName="Deck and Patio Construction"
                url={ROUTES.DECK_AND_PATIO_CONSTRUCTION}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.FENCING}`}
            element={<TradePage tradeName="Fencing" url={ROUTES.FENCING} />}
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.WATERPROOFING}`}
            element={
              <TradePage tradeName="Waterproofing" url={ROUTES.WATERPROOFING} />
            }
          />

          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.CREDIT_RESTORATION_SERVICES}`}
            element={
              <TradePage
                tradeName="Credit Restoration Services"
                url={ROUTES.CREDIT_RESTORATION_SERVICES}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.CREDIT_BUILDING_PLATFORMS}`}
            element={
              <TradePage
                tradeName="Credit Building Platforms"
                url={ROUTES.CREDIT_BUILDING_PLATFORMS}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.DEBT_RELIEF}`}
            element={
              <TradePage tradeName="Debt Relief" url={ROUTES.DEBT_RELIEF} />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.CERTIFIED_PUBLIC_ACCOUNTANT}`}
            element={
              <TradePage
                tradeName="Certified Public Accountant"
                url={ROUTES.CERTIFIED_PUBLIC_ACCOUNTANT}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.PAYROLL_AND_HR_SERVICES}`}
            element={
              <TradePage
                tradeName="Payroll and HR Services"
                url={ROUTES.PAYROLL_AND_HR_SERVICES}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.ESTATE_AND_TRUST_PLANNING}`}
            element={
              <TradePage
                tradeName="Estate and Trust Planning"
                url={ROUTES.ESTATE_AND_TRUST_PLANNING}
              />
            }
          />

          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.LIFE_INSURANCE_COMPANIES}`}
            element={
              <TradePage
                tradeName="Life Insurance Companies"
                url={ROUTES.LIFE_INSURANCE_COMPANIES}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.HEALTH_INSURANCE_PROVIDERS}`}
            element={
              <TradePage
                tradeName="Health Insurance Providers"
                url={ROUTES.HEALTH_INSURANCE_PROVIDERS}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.AUTO_INSURANCE_COMPANIES}`}
            element={
              <TradePage
                tradeName="Auto Insurance Companies"
                url={ROUTES.AUTO_INSURANCE_COMPANIES}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.HOMEOWNERS_INSURANCE_PROVIDERS}`}
            element={
              <TradePage
                tradeName="Homeowners Insurance Providers"
                url={ROUTES.HOMEOWNERS_INSURANCE_PROVIDERS}
              />
            }
          />
          <Route
            path={`${ROUTES.WHO_WE_SERVE}${ROUTES.COMMERCIAL_INSURANCE_PROVIDERS}`}
            element={
              <TradePage
                tradeName="Commercial Insurance Providers"
                url={ROUTES.COMMERCIAL_INSURANCE_PROVIDERS}
              />
            }
          />

          <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={ROUTES.TERMS_OF_USE} element={<TermsOfUse />} />

          <Route path={ROUTES.SUPPORT} element={<Support />} />
          <Route
            path={ROUTES.HOW_TO_CREATE_FORM}
            element={<HowToCreateAForm />}
          />
          <Route
            path={ROUTES.HOW_TO_ADD_PRIVACY_POLICY_AND_BUSINESS_INFO}
            element={<HowToAddPrivacyPolicyAndBusinessInfo />}
          />
          <Route
            path={ROUTES.HOW_TO_INTEGRATE_JOBBER}
            element={<HowToIntegrateJobber />}
          />
          <Route
            path={ROUTES.HOW_TO_INTEGRATE_WORKIZ}
            element={<HowToIntegrateWorkiz />}
          />
          <Route
            path={ROUTES.HOW_TO_INTEGRATE_LEAD_CONNECTOR}
            element={<HowToIntegrateLeadConnector />}
          />
          <Route
            path={ROUTES.HOW_TO_INTEGRATE_BUILDER_PRIME}
            element={<HowToIntegrateBuilderPrime />}
          />
          <Route
            path={ROUTES.HOW_TO_TRACK_FACEBOOK_AD}
            element={<HowToTrackFacebookAd />}
          />
          <Route
            path={ROUTES.HOW_TO_TRACK_GOOGLE_ADS}
            element={<HowToTrackGoogleAds />}
          />

          <Route path={ROUTES.PAYMENT_SUCCESS} element={<PaymentSuccess />} />

          <Route
            path={ROUTES.PRO_ONBOARDING_SETUP}
            element={<ProOnboardingSetup />}
          />

          <Route path="*" element={<Navigate to={ROUTES.HOME} replace />} />
        </Routes>
      </div>
    );
  } else if (
    subdomain === process.env.REACT_APP_TRY?.split(".")[0] ||
    subdomain === process.env.REACT_APP_AGENCY?.split(".")[0]
  ) {
    return (
      <div className={styles.wrapper}>
        <Routes>
          {subdomain === process.env.REACT_APP_TRY?.split(".")[0] ? (
            <Route path={ROUTES.HOME} element={<SalesFunnel />} />
          ) : (
            subdomain === process.env.REACT_APP_AGENCY?.split(".")[0] && (
              <Route path={ROUTES.HOME} element={<Agency />} />
            )
          )}

          <Route path={ROUTES.GOOGLE_AUTH} element={<GoogleLogin />} />

          <Route path={ROUTES.SIGNUP} element={<SignUp />} />
          <Route path={ROUTES.LOGIN} element={<LogIn />} />

          <Route path={ROUTES.ACTIVATE_USER} element={<ActivateUserPage />} />

          <Route
            path={ROUTES.FORGOT_PASSWORD}
            element={
              <ForgotPassword type={process.env.REACT_APP_TRY?.split(".")[0]} />
            }
          />
          <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={ROUTES.CHANGE_EMAIL} element={<ChangeEmail />} />

          <Route
            path={ROUTES.PAYMENT_SUCCESS}
            element={<PaymentSuccess isTrial />}
          />

          <Route path="*" element={<Navigate to={ROUTES.HOME} replace />} />
        </Routes>
      </div>
    );
  } else {
    return (
      <div className={styles.wrapper}>
        <Routes>
          <Route path={ROUTES.HOME} element={<FormView />} />
          <Route path={ROUTES.THANK_YOU} element={<ThankYouPage />} />
          <Route path="*" element={<Navigate to={ROUTES.HOME} replace />} />
        </Routes>
      </div>
    );
  }
}

export default App;
