import { FORM_BUILDER_ANSWER_TYPES } from "src/_utils/formBuilderAnswerTypes";
import {
  ADD_ANSWER_OPTION,
  ADD_ANSWER_TYPE_TO_QUESTION,
  CLEAR_USER_ANSWERS,
  CURRENT_CREATE_FORM_PAGE,
  CURRENT_FORM_PAGE,
  EDIT_ICON_ANSWER_OPTION_TO_QUESTION,
  EDIT_TEXT_ANSWER_OPTION_TO_QUESTION,
  OBSERVE_ADDITIONAL_PURCHASE_ADDED,
  OBSERVE_NEXT_PLAN_CHANGED,
  REMOVE_LAST_USER_ANSWERS,
  REMOVE_USER_CONDITIONAL_ANSWERS,
  SET_ALL_ANSWERS,
  SET_COMPANY_LOGO,
  SET_COMPANY_NAME,
  SET_CONDITIONAL_ANSWER_OPTIONS_FOR_QUESTION,
  SET_CONTINUE_BUTTON_DISABLED,
  SET_CURRENT_USER_PLAN,
  SET_ENDING_PAGE_TEXT,
  SET_FORM_COLOR_THEME,
  SET_HOW_SHALL_WE_CONTACT_YOU_ERROR,
  SET_HSWCY_OTHER_INFO,
  SET_HSWCY_TITLE,
  SET_IS_ANY_ANSWER_OPTIONS,
  SET_IS_BACK_PRESSED,
  SET_IS_HIDE_POWERED_LABEL,
  SET_IS_CURRENT_USER_PLAN_LOADING,
  SET_IS_GOOGLE_AUTH_ERROR,
  SET_IS_MULTIPLE_SELECT_ANSWER,
  SET_IS_SHOW_CALENDLY_MODAL,
  SET_IS_SHOW_EDIT_FORM_MODAL,
  SET_IS_SHOW_LOGIN_MODAL,
  SET_IS_SHOW_PRICING_MODAL,
  SET_IS_SHOW_REGISTER_MODAL,
  SET_IS_USER_PLAN_ACTIVE,
  SET_QUESTIONS,
  SET_SELECTED_FORM_TEMPLATE_ID,
  SET_USER_ANSWERS,
  SET_USER_INFO,
  SET_USER_NAME,
  UPDATE_ANSWER_OPTION_ICON,
  UPDATE_ANSWER_OPTION_TEXT,
  SET_IS_HIDE_LOGO,
} from "./actions";
import COLORS from "src/_utils/colors/colors";

const initialState = {
  isGoogleAuthError: false,
  companyName: "",
  companyLogo: null,
  currentCreateFormPage: 0,
  currentFormPage: 0,
  questionsList: [
    {
      id: 0,
      question: "",
      answerOptions: [
        {
          title: "",
          icon: "",
        },
      ],
      answerType: FORM_BUILDER_ANSWER_TYPES.textArea,
      isMultipleSelect: false,
      conditionalAnswerOptions: null,
    },
  ],
  userAnswers: [{ question: "", answer: null }],
  isContinueButtonDisabled: true,
  isAnyAnswerOptions: false,
  endingPageText: "Thank you!",
  userInfo: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    date: "",
    time: "",
    address: "",
    zipOrPostal: "",
  },
  userName: "",
  howShallWeContactYou: {
    errorMessage: false,
  },
  observeNextPlanChanged: false,
  observeAdditionalPurchaseAdded: false,
  isUserPlanActive: false,
  currentUserPlan: null,
  isCurrentUserPlanLoading: true,
  isShowPricingModal: false,
  isShowLoginModal: false,
  isShowRegisterModal: false,
  calendlyModalData: { show: false, url: undefined },
  HSWCYOtherInfo: null,
  HSWCYOTitle: "How shall we contact you",
  isShowEditFormModal: false,
  selectedFormTemplateId: "",
  currentConditionalQuestionsIDs: null,
  allAnswers: [],
  isBackPressed: false,
  formColorTheme: COLORS.BLUE,
  isHidePoweredLabel: false,
  isHideLogo: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_COMPANY_NAME:
      return {
        ...state,
        companyName: action.payload,
      };
    case SET_COMPANY_LOGO:
      return {
        ...state,
        companyLogo: action.payload,
      };
    case CURRENT_CREATE_FORM_PAGE:
      return {
        ...state,
        currentCreateFormPage: action.payload,
      };
    case CURRENT_FORM_PAGE:
      return {
        ...state,
        currentFormPage: action.payload,
      };
    case SET_QUESTIONS:
      return {
        ...state,
        questionsList: action.payload,
      };
    case SET_USER_ANSWERS:
      const updatedAnswers = state.userAnswers ? [...state.userAnswers] : [];
      updatedAnswers[action.payload.index] = action.payload.answer;
      return {
        ...state,
        userAnswers: updatedAnswers,
      };
    case REMOVE_LAST_USER_ANSWERS:
      return {
        ...state,
        userAnswers: state.userAnswers.filter(
          (_, index) => index !== action.payload
        ),
      };
    case CLEAR_USER_ANSWERS:
      return {
        ...state,
        userAnswers: [],
      };
    case ADD_ANSWER_OPTION:
      const { questionIndex, answerText, answerOptionId } = action.payload;
      return {
        ...state,
        questionsList: state.questionsList.map((question, qIdx) => {
          if (qIdx === questionIndex) {
            return {
              ...question,
              answerOptions: [
                ...(question.answerOptions || []),
                { title: answerText, id: answerOptionId },
              ],
            };
          }
          return question;
        }),
      };
    case ADD_ANSWER_TYPE_TO_QUESTION:
      return {
        ...state,
        questionsList: state.questionsList.map((question, qIdx) => {
          if (qIdx === action.payload.questionIndex) {
            return {
              ...question,
              answerType: action.payload.answerType,
            };
          }
          return question;
        }),
      };
    case SET_IS_MULTIPLE_SELECT_ANSWER:
      return {
        ...state,
        questionsList: state.questionsList.map((question, qIdx) => {
          if (qIdx === action.payload.questionIndex) {
            return {
              ...question,
              isMultipleSelect: action.payload.isMultipleSelect,
            };
          }
          return question;
        }),
      };
    case EDIT_TEXT_ANSWER_OPTION_TO_QUESTION:
      return {
        ...state,
        questionsList: state.questionsList.map((question, qIndex) =>
          qIndex === action.payload.questionIndex
            ? {
                ...question,
                answerOptions: (question.answerOptions || []).map(
                  (option, aIndex) =>
                    aIndex === action.payload.answerOptionIndex
                      ? {
                          ...option,
                        }
                      : option
                ),
              }
            : question
        ),
      };
    case EDIT_ICON_ANSWER_OPTION_TO_QUESTION:
      return {
        ...state,
        questionsList: state.questionsList.map((question, qIndex) =>
          qIndex === action.payload.questionIndex
            ? {
                ...question,
                answerOptions: question.answerOptions,
              }
            : question
        ),
      };
    case UPDATE_ANSWER_OPTION_TEXT:
      const { questionIndex: qIndex, optionIndex, newText } = action.payload;
      return {
        ...state,
        questionsList: state.questionsList.map((question, qIdx) => {
          if (qIdx === qIndex) {
            return {
              ...question,
              answerOptions: question.answerOptions.map((option, oIdx) => {
                if (oIdx === optionIndex) {
                  return { ...option, title: newText };
                }
                return option;
              }),
            };
          }
          return question;
        }),
      };
    case UPDATE_ANSWER_OPTION_ICON:
      return {
        ...state,
        questionsList: state.questionsList.map((question, qIdx) => {
          if (qIdx === action.payload.questionIndex) {
            return {
              ...question,
              answerOptions: question.answerOptions.map((option, oIdx) => {
                if (oIdx === action.payload.optionIndex) {
                  return {
                    ...option,
                    icon: action.payload.newIcon,
                    iconFile: action.payload.iconFile,
                  };
                }
                return option;
              }),
            };
          }
          return question;
        }),
      };
    case SET_CONTINUE_BUTTON_DISABLED:
      return {
        ...state,
        isContinueButtonDisabled: action.payload,
      };
    case SET_IS_ANY_ANSWER_OPTIONS:
      return {
        ...state,
        isAnyAnswerOptions: action.payload,
      };
    case SET_IS_GOOGLE_AUTH_ERROR:
      return {
        ...state,
        isGoogleAuthError: action.payload,
      };
    case SET_ENDING_PAGE_TEXT:
      return {
        ...state,
        endingPageText: action.payload,
      };
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case SET_USER_NAME:
      return {
        ...state,
        userName: action.payload,
      };
    case SET_HOW_SHALL_WE_CONTACT_YOU_ERROR:
      return {
        ...state,
        howShallWeContactYou: {
          errorMessage: action.payload,
        },
      };
    case OBSERVE_NEXT_PLAN_CHANGED:
      return {
        ...state,
        observeNextPlanChanged: action.payload,
      };
    case OBSERVE_ADDITIONAL_PURCHASE_ADDED:
      return {
        ...state,
        observeAdditionalPurchaseAdded: action.payload,
      };
    case SET_IS_USER_PLAN_ACTIVE:
      return {
        ...state,
        isUserPlanActive: action.payload,
      };
    case SET_CURRENT_USER_PLAN:
      return {
        ...state,
        currentUserPlan: action.payload,
      };
    case SET_IS_CURRENT_USER_PLAN_LOADING:
      return {
        ...state,
        isCurrentUserPlanLoading: action.payload,
      };
    case SET_IS_SHOW_PRICING_MODAL:
      return {
        ...state,
        isShowPricingModal: action.payload,
      };
    case SET_IS_SHOW_LOGIN_MODAL:
      return {
        ...state,
        isShowLoginModal: action.payload,
      };
    case SET_IS_SHOW_REGISTER_MODAL:
      return {
        ...state,
        isShowRegisterModal: action.payload,
      };
    case SET_IS_SHOW_CALENDLY_MODAL:
      return {
        ...state,
        calendlyModalData: action.payload,
      };
    case SET_HSWCY_OTHER_INFO:
      return {
        ...state,
        HSWCYOtherInfo: action.payload,
      };
    case SET_HSWCY_TITLE:
      return {
        ...state,
        HSWCYOTitle: action.payload,
      };
    case SET_IS_SHOW_EDIT_FORM_MODAL:
      return {
        ...state,
        isShowEditFormModal: action.payload,
      };
    case SET_SELECTED_FORM_TEMPLATE_ID:
      return {
        ...state,
        selectedFormTemplateId: action.payload,
      };
    case SET_CONDITIONAL_ANSWER_OPTIONS_FOR_QUESTION:
      return {
        ...state,
        questionsList: state.questionsList.map((question) =>
          question.id === action.payload.questionId
            ? {
                ...question,
                conditionalAnswerOptions: {
                  value: action.payload.value,
                  isHide: action.payload.isHide,
                },
              }
            : question
        ),
      };
    case SET_ALL_ANSWERS:
      return {
        ...state,
        allAnswers: [...state.allAnswers, action.payload],
      };
    case REMOVE_USER_CONDITIONAL_ANSWERS:
      return {
        ...state,
        allAnswers: state.allAnswers.filter(
          (answer) => answer !== action.payload.toString()
        ),
      };
    case SET_IS_BACK_PRESSED:
      return {
        ...state,
        isBackPressed: action.payload,
      };
    case SET_FORM_COLOR_THEME:
      return {
        ...state,
        formColorTheme: action.payload,
      };
    case SET_IS_HIDE_POWERED_LABEL:
      return {
        ...state,
        isHidePoweredLabel: action.payload,
      };
    case SET_IS_HIDE_LOGO:
      return {
        ...state,
        isHideLogo: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
