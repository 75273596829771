import Modal from "src/_elements/modal/modal";
import styles from "./shareFormModal.module.scss";
import TextArea from "src/_elements/textArea/textArea";
import { useEffect, useState } from "react";

const ShareFormModal: React.FC<any> = ({
  isShow,
  handleClose,
  selectedForm,
}: any) => {
  const [formIframeCode, setFormIframeCode] = useState("");

  useEffect(() => {
    setFormIframeCode(
      `<!-- LeadQuiz Snippet -->
      <script
        type="text/javascript"
        src="https://${process.env.REACT_APP_DOMAIN}/embed.js"
        subdomain=${selectedForm?.cname?.subDomain}
    ></script>
<!-- End LeadQuiz Snippet -->`
    );
  }, [selectedForm]);

  return (
    <Modal isShow={isShow} onClose={handleClose}>
      <div className={styles.content}>
        <p className={styles.title}>
          You can embed your published form into your website with an HTML
          snippet
        </p>
        <TextArea value={formIframeCode} />
      </div>
    </Modal>
  );
};

export default ShareFormModal;
