import ContentSection from "src/pages/whoWeServe/tradePage/contentSection/contentSection";
import graphArrowIcon from "src/assets/howItWorks/graphArrow.svg";
import easyToUseIcon from "src/assets/howItWorks/easyToUse.svg";
import homeIcon from "src/assets/howItWorks/home.svg";
import automatePeopleIcon from "src/assets/howItWorks/automatePeople.svg";
import diamondIcon from "src/assets/howItWorks/diamond.svg";
import arrowUpIcon from "src/assets/arrowUp.svg";
import phoneAndLaptopImg from "src/assets/howItWorks/phoneAndLaptop.png";
import leadGenerationImg from "src/assets/howItWorks/leadGeneration.svg";
import macStudioImg from "src/assets/howItWorks/macStudio.png";
import automateImg from "src/assets/howItWorks/automate.svg";
import samsungTvImg from "src/assets/howItWorks/samsungTv.png";
import rocketImg from "src/assets/howItWorks/rocket.svg";
import styles from "./howItWorksContent.module.scss";

const HowItWorksContent = () => {
  return (
    <div className={styles.contentWrap}>
      <ContentSection
        icon={graphArrowIcon}
        title="Maximize Results from Facebook & Google Ads"
        img={phoneAndLaptopImg}
        description="LeadQuiz integrates seamlessly with Facebook Ads and Google Ads, helping you capture leads directly from these platforms. Whether promoting seasonal offers or core services, our customizable forms convert clicks into qualified leads, boosting your ad performance."
        title1="Customizable Forms"
        title2="Higher Conversion Rates"
        subtitle1="Tailor quizzes and forms to match your brand and service offering."
        subtitle2="Engage potential customers with relevant, interactive content that
    drives results."
      />
      <ContentSection
        isReversed
        icon={easyToUseIcon}
        title="Simplify Your Lead Generation with LeadQuiz"
        img={leadGenerationImg}
        description="LeadQuiz is built specifically to help businesses generate high-quality leads, especially through Facebook and Google Ads. Whether you offer HVAC, plumbing, landscaping, or any other service, our platform enables you to create customized quizzes and forms that attract the right audience and convert them into paying customers directly from your advertising campaigns."
      />
      <ContentSection
        icon={homeIcon}
        title="Built for Your Business"
        img={macStudioImg}
        description="LeadQuiz lets you create branded lead forms without needing technical skills. Simply customize it to your specific services and start attracting leads that match your business goals."
        title1="Easy-to-Use Interface"
        title2="Tailored for everything"
        subtitle1="No coding needed—just drag, drop, and customize."
        subtitle2="Designed to meet the unique needs."
      />
      <ContentSection
        isReversed
        icon={automatePeopleIcon}
        title="Automate and Streamline Your Lead Management"
        img={automateImg}
        description="LeadQuiz integrates effortlessly with the CRMs you already use, like Jobber, HubSpot, and others. Our Zapier integration automates lead data flow, so you can focus on delivering excellent service instead of dealing with manual data entry. This allows you to stay organized, follow up quickly, and never miss an opportunity."
      />
      <ContentSection
        icon={diamondIcon}
        title="Capture High-Quality Leads Every Time"
        img={samsungTvImg}
        description="Not all leads are created equal. LeadQuiz’s smart forms filter out low-quality leads, providing you with validated contact details, so you can focus on the right prospects."
        title1="Smart Filtering"
        title2="Validated Contact Info"
        subtitle1="Receive only high-intent leads."
        subtitle2="Get accurate phone numbers and emails."
      />
      <ContentSection
        isReversed
        icon={arrowUpIcon}
        title="Grow Your Business with Confidence"
        img={rocketImg}
        description="LeadQuiz helps businesses like yours grow by delivering high-quality, actionable leads from your Facebook and Google Ads. <b>With our platform, you can boost your marketing performance, keep your job calendar full, and scale your business faster.</b>"
      />
    </div>
  );
}

export default HowItWorksContent;
