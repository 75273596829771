import { useDispatch } from "react-redux";
import styles from "./top.module.scss";
import videoAnimation from "src/assets/howItWorks/videoAnimation.mp4";
import {
  setIsShowCalendlyModalAC,
  setIsShowPricingModalAC,
  setIsShowRegisterModalAC,
} from "src/store/actions";
import videoThumbnail from "src/assets/howItWorks/videoThumbnail.png";

const Top = () => {
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  return (
    <div className={styles.wrapper}>
      <div className={styles.textWrap}>
        <h1>Simplify Your Lead Generation with LeadQuiz</h1>
        <p className={styles.subtitle}>
          LeadQuiz is built specifically to help businesses generate
          high-quality leads, whether you offer HVAC, plumbing, landscaping, or
          any other service
        </p>
        <div className={styles.buttonsWrap}>
          <div className={styles.getStartedButton}>
            <button
              onClick={
                token
                  ? () => dispatch(setIsShowPricingModalAC(true))
                  : () => dispatch(setIsShowRegisterModalAC(true))
              }
            >
              Get started today
            </button>
          </div>
          <button
            className={styles.demoButton}
            onClick={() =>
              dispatch(
                setIsShowCalendlyModalAC({
                  show: true,
                  url: process.env.REACT_APP_CALENDLY_DEMO_URL!,
                })
              )
            }
          >
            Schedule a demo
          </button>
        </div>
      </div>
      <div className={styles.videoSection}>
        <div className={styles.video}>
          <video width="100%" height="100%" controls poster={videoThumbnail}>
            <source src={videoAnimation} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default Top;
