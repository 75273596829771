import React, { useEffect, useState } from "react";
import styles from "./howShallWeContactYou.module.scss";
import FormInput from "src/_elements/formInput/formInput";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfoAC, setUserNameAC } from "src/store/actions";
import TextArea from "src/_elements/textArea/textArea";
import DatePickerComponent from "src/_elements/datePickerComponent/datePickerComponent";
import { format } from "date-fns";
import TimePicker from "src/_elements/timePicker/timePicker";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { E164Number } from "libphonenumber-js";

const removeSpaces = (str: string) => str.replace(/\s+/g, "");

const HowShallWeContactYou: React.FC<any> = ({ formColorTheme }: any) => {
  const dispatch = useDispatch();

  const errorMessage = useSelector(
    (state: any) => state.howShallWeContactYou.errorMessage
  );
  const questionsList = useSelector((state: any) => state.questionsList);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<E164Number>();
  const [email, setEmail] = useState("");
  const [dynamicAnswers, setDynamicAnswers] = useState<any>({});

  useEffect(() => {
    const userInfo = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      email: email,
      ...dynamicAnswers,
    };

    dispatch(setUserNameAC(firstName + " " + lastName));
    dispatch(setUserInfoAC(userInfo));
  }, [firstName, lastName, phoneNumber, email, dynamicAnswers]);

  const generateUniqueLabel = (
    label: string,
    index: number,
    labelsCount: any
  ) => {
    const cleanedLabel = removeSpaces(label);
    if (labelsCount[cleanedLabel] > 1) {
      return `${cleanedLabel}${index + 1}`;
    }
    return cleanedLabel;
  };

  const countLabels = (questions: any) => {
    return questions.reduce((acc: any, question: any) => {
      const cleanedLabel = removeSpaces(question.label);
      acc[cleanedLabel] = (acc[cleanedLabel] || 0) + 1;
      return acc;
    }, {});
  };

  const handleDynamicInputChange = (
    value: any,
    label: string,
    type: string
  ) => {
    const cleanedLabel = removeSpaces(label);
    setDynamicAnswers((prev: any) => ({
      ...prev,
      [cleanedLabel]: type === "date" ? format(value, "MM/dd/yyyy") : value,
    }));
  };

  const renderFormElement = (
    question: any,
    index: number,
    labelsCount: any
  ) => {
    const uniqueLabel = generateUniqueLabel(question.label, index, labelsCount);

    switch (question.type) {
      case "date":
        return (
          <div key={uniqueLabel}>
            {/* <p className={styles.inputLabel}>{question.label}:</p> */}
            <DatePickerComponent
              value={dynamicAnswers[uniqueLabel] || ""}
              handleChange={(date: string) =>
                handleDynamicInputChange(date, uniqueLabel, "date")
              }
              color={formColorTheme}
            />
          </div>
        );
      case "time":
        return (
          <div key={uniqueLabel}>
            {/* <p className={styles.inputLabel}>{question.label}:</p> */}
            <TimePicker
              handleChange={(time: any) =>
                handleDynamicInputChange(time, uniqueLabel, "time")
              }
              color={formColorTheme}
            />
          </div>
        );
      case "text":
        return (
          <div key={uniqueLabel}>
            {/* <p className={styles.inputLabel}>{question.label}:</p> */}
            <FormInput
              type="text"
              value={dynamicAnswers[uniqueLabel] || ""}
              onChange={(e) =>
                handleDynamicInputChange(e.target.value, uniqueLabel, "text")
              }
              placeholder={question.label}
              label={question.label}
              color={formColorTheme}
            />
          </div>
        );
      case "textarea":
        return (
          <div key={uniqueLabel}>
            {/* <p className={styles.inputLabel}>{question.label}:</p> */}
            <TextArea
              value={dynamicAnswers[uniqueLabel] || ""}
              onChange={(e) =>
                handleDynamicInputChange(
                  e.target.value,
                  uniqueLabel,
                  "textarea"
                )
              }
              placeholder={question.label}
              label={question.label}
              color={formColorTheme}
            />
          </div>
        );
      case "city":
        return (
          <div key={uniqueLabel}>
            <FormInput
              value={dynamicAnswers[uniqueLabel] || ""}
              onChange={(e) =>
                handleDynamicInputChange(e.target.value, uniqueLabel, "city")
              }
              placeholder={question.label}
              label={question.label}
              color={formColorTheme}
            />
          </div>
        );
      case "state":
        return (
          <div key={uniqueLabel}>
            <FormInput
              value={dynamicAnswers[uniqueLabel] || ""}
              onChange={(e) =>
                handleDynamicInputChange(e.target.value, uniqueLabel, "state")
              }
              placeholder={question.label}
              label={question.label}
              color={formColorTheme}
            />
          </div>
        );
      case "zipCode":
        return (
          <div key={uniqueLabel}>
            <FormInput
              value={dynamicAnswers[uniqueLabel] || ""}
              onChange={(e) =>
                handleDynamicInputChange(e.target.value, uniqueLabel, "zipCode")
              }
              placeholder={question.label}
              label={question.label}
              color={formColorTheme}
            />
          </div>
        );
      case "address":
        return (
          <div key={uniqueLabel}>
            <FormInput
              value={dynamicAnswers[uniqueLabel] || ""}
              onChange={(e) =>
                handleDynamicInputChange(e.target.value, uniqueLabel, "address")
              }
              placeholder={question.label}
              label={question.label}
              color={formColorTheme}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.questionTitle}>
        {questionsList.howShallWeContactYou.title}
      </p>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
      <div className={styles.content}>
        <div className={styles.nameSection}>
          <div>
            <FormInput
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First name"
              label="First name"
              color={formColorTheme}
            />
          </div>
          <div>
            <FormInput
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last name"
              label="Last name"
              color={formColorTheme}
            />
          </div>
        </div>
        <div
          className={styles.phoneNumberInputWrapper}
          style={{ border: `1px solid ${formColorTheme}` }}
        >
          <PhoneInput
            placeholder="Phone number"
            value={phoneNumber}
            onChange={(value: E164Number) => setPhoneNumber(value)}
            className={styles.phoneInput}
            defaultCountry="US"
          />
        </div>
        <FormInput
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          label="Email"
          color={formColorTheme}
        />
        <div className={styles.otherInfo}>
          {questionsList.howShallWeContactYou.otherInfo.map(
            (question: any, index: number) => {
              const labelsCount = countLabels(
                questionsList.howShallWeContactYou.otherInfo
              );
              return renderFormElement(question, index, labelsCount);
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default HowShallWeContactYou;
