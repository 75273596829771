import { Link } from "react-router-dom";
import styles from "./whoWeServeList.module.scss";
import ROUTES from "src/_utils/routes/routes";
import generalContracting from "src/assets/whoWeServe/generalContracting.png";
import painting from "src/assets/whoWeServe/painting.png";
import roofing from "src/assets/whoWeServe/roofing.png";
import solar from "src/assets/whoWeServe/solar.png";
import plumbing from "src/assets/whoWeServe/plumbing.png";
import electrical from "src/assets/whoWeServe/electrical.png";
import arrow from "src/assets/arrow.svg";
import creditRestorationServices from "src/assets/whoWeServe/financialServices/creditRestorationServices.svg";
import creditBuildingPlatforms from "src/assets/whoWeServe/financialServices/creditBuildingPlatforms.svg";
import debtRelief from "src/assets/whoWeServe/financialServices/debtRelief.svg";
import certifiedPublicAccountServices from "src/assets/whoWeServe/financialServices/certifiedPublicAccountServices.svg";
import payrollHRServices from "src/assets/whoWeServe/financialServices/payrollHRServices.svg";
import estateTrust from "src/assets/whoWeServe/financialServices/estateTrust.svg";
import life from "src/assets/whoWeServe/insurance/life.svg";
import health from "src/assets/whoWeServe/insurance/health.svg";
import auto from "src/assets/whoWeServe/insurance/auto.svg";
import home from "src/assets/whoWeServe/insurance/home.svg";
import commercial from "src/assets/whoWeServe/insurance/commercial.svg";

const WhoWeServeList: React.FC<any> = ({ setIsWhoWeServeListOpen }: any) => {
  const handleSelectTrade = () => {
    setIsWhoWeServeListOpen(false);
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>POPULAR TRADES</p>
      <div className={styles.tradesList}>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.GENERAL_CONTRACTING}`}
          onClick={handleSelectTrade}
        >
          <img src={generalContracting} alt="general contracting" />
          <p>General Contracting</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.PAINTING}`}
          onClick={handleSelectTrade}
        >
          <img src={painting} alt="painting" />
          <p>Painting</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.ROOFING}`}
          onClick={handleSelectTrade}
        >
          <img src={roofing} alt="roofing" />
          <p>Roofing</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.SOLAR}`}
          onClick={handleSelectTrade}
        >
          <img src={solar} alt="solar" />
          <p>Solar</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.PLUMBING}`}
          onClick={handleSelectTrade}
        >
          <img src={plumbing} alt="plumbing" />
          <p>Plumbing</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.ELECTRICAL}`}
          onClick={handleSelectTrade}
        >
          <img src={electrical} alt="electrical" />
          <p>Electrical</p>
        </Link>
      </div>
      <Link to={ROUTES.WHO_WE_SERVE} className={styles.exploreMore}>
        Explore All Trades <img src={arrow} alt="arrow" />
      </Link>
      <br />
      <br />
      <p className={styles.title}>FINANCIAL SERVICES</p>
      <div className={styles.tradesList}>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.CREDIT_RESTORATION_SERVICES}`}
          onClick={handleSelectTrade}
        >
          <img
            src={creditRestorationServices}
            alt="credit restoration services"
          />
          <p>Credit Restoration Services</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.CREDIT_BUILDING_PLATFORMS}`}
          onClick={handleSelectTrade}
        >
          <img src={creditBuildingPlatforms} alt="Credit Building Platforms" />
          <p>Credit Building Platforms</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.DEBT_RELIEF}`}
          onClick={handleSelectTrade}
        >
          <img src={debtRelief} alt="debtRelief" />
          <p>Debt Relief</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.CERTIFIED_PUBLIC_ACCOUNTANT}`}
          onClick={handleSelectTrade}
        >
          <img
            src={certifiedPublicAccountServices}
            alt="Certified Public Accountant"
          />
          <p>Certified Public Accountant</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.PAYROLL_AND_HR_SERVICES}`}
          onClick={handleSelectTrade}
        >
          <img src={payrollHRServices} alt="Payroll HR services" />
          <p>Payroll and HR Services</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.ESTATE_AND_TRUST_PLANNING}`}
          onClick={handleSelectTrade}
        >
          <img src={estateTrust} alt="Estate and Trust Planning" />
          <p>Estate and Trust Planning</p>
        </Link>
      </div>
      <br />
      <br />
      <p className={styles.title}>INSURANCE</p>
      <div className={styles.tradesList}>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.LIFE_INSURANCE_COMPANIES}`}
          onClick={handleSelectTrade}
        >
          <img src={life} alt="Life Insurance Companies" />
          <p>Life Insurance Companies</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.HEALTH_INSURANCE_PROVIDERS}`}
          onClick={handleSelectTrade}
        >
          <img src={health} alt="Health Insurance Providers" />
          <p>Health Insurance Providers</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.AUTO_INSURANCE_COMPANIES}`}
          onClick={handleSelectTrade}
        >
          <img src={auto} alt="Auto Insurance Companies" />
          <p>Auto Insurance Companies</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.HOMEOWNERS_INSURANCE_PROVIDERS}`}
          onClick={handleSelectTrade}
        >
          <img src={home} alt="Homeowners Insurance Providers" />
          <p>Homeowners Insurance Providers</p>
        </Link>
        <Link
          to={`${ROUTES.WHO_WE_SERVE}${ROUTES.COMMERCIAL_INSURANCE_PROVIDERS}`}
          onClick={handleSelectTrade}
        >
          <img src={commercial} alt="Commercial Insurance Providers" />
          <p>Commercial Insurance Providers</p>
        </Link>
      </div>
    </div>
  );
};

export default WhoWeServeList;
