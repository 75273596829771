import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  removeLastUserAnswerAC,
  removeUserConditionalAnswerAC,
  setAllAnswersAC,
  setCurrentFormPageAC,
  setIsBackPressedAC,
} from "src/store/actions";
import styles from "./formNavigation.module.scss";

const FormNavigation: React.FC<any> = ({
  content,
  isLastPage,
  setAnswerIndex,
  selectedAnswer,
  setSelectedAnswer,
  formColorTheme,
}: any) => {
  const dispatch = useDispatch();
  const currentIndex = useSelector((state: any) => state.currentFormPage);
  const userAnswers = useSelector((state: any) => state.userAnswers);
  const allAnswers = useSelector((state: any) => state.allAnswers);

  const [pureCurrentIndex, setPureCurrentIndex] = useState(0);

  const [indexHistory, setIndexHistory] = useState<number[]>([]);

  const handleNext = () => {
    dispatch(setIsBackPressedAC(false));
    selectedAnswer && dispatch(setAllAnswersAC(selectedAnswer?.id));
    setPureCurrentIndex((prevState) => prevState + 1);

    if (currentIndex < content.length) {
      setIndexHistory((prevHistory) => [...prevHistory, currentIndex]);
      dispatch(setCurrentFormPageAC(currentIndex + 1));
    }
    setAnswerIndex((prevState: number) => prevState + 1);
    setSelectedAnswer(null);
  };

  useEffect(() => {
    selectedAnswer && handleNext();
  }, [selectedAnswer]);

  const handleBack = () => {
    dispatch(removeLastUserAnswerAC(pureCurrentIndex));
    dispatch(removeLastUserAnswerAC(pureCurrentIndex - 1));

    if (indexHistory.length === 0) return;

    dispatch(setIsBackPressedAC(true));
    selectedAnswer && dispatch(setAllAnswersAC(selectedAnswer?.id));
    setPureCurrentIndex((prevState) => prevState - 1);

    const previousIndex = indexHistory[indexHistory.length - 1];
    setIndexHistory((prevHistory) => prevHistory.slice(0, -1));

    dispatch(setCurrentFormPageAC(previousIndex));
    setAnswerIndex((prevState: number) => --prevState);
    setSelectedAnswer(null);

    const currentElementWithConditions = content[pureCurrentIndex - 1];

    if (
      currentElementWithConditions &&
      currentElementWithConditions.answerOptions
    ) {
      currentElementWithConditions.answerOptions.forEach((option: any) => {
        if (allAnswers.some((answer: any) => answer === option.id)) {
          if (!content[previousIndex]?.conditionalAnswerOptions?.isHide) {
            setTimeout(() => {
              dispatch(removeUserConditionalAnswerAC(option.id));
            }, 100);
          } else {
            dispatch(removeUserConditionalAnswerAC(option.id));
          }
        }
      });
    }
  };

  const isContinueDisabled =
    userAnswers.length < 1 ||
    !userAnswers[pureCurrentIndex]?.answer ||
    userAnswers[pureCurrentIndex]?.answer === "";

  const isBackDisabled = pureCurrentIndex === 0;

  return (
    <div
      className={`${
        !isLastPage ? styles.buttonsWrap : styles.buttonsWrapLastPage
      }`}
    >
      <button
        className={`${styles.navButton} ${
          isBackDisabled ? styles.disabled : ""
        }`}
        onClick={handleBack}
        disabled={isBackDisabled}
      >
        ← Back
      </button>
      {!isLastPage && (
        <button
          className={`${styles.navButton} ${styles.nextBtn} ${
            isContinueDisabled ? styles.disabled : ""
          }`}
          onClick={handleNext}
          disabled={isContinueDisabled}
          style={{ backgroundColor: formColorTheme }}
        >
          Next →
        </button>
      )}
    </div>
  );
};

export default FormNavigation;
