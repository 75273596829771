import { IPlan } from "src/pages/interfaces/interfaces";
import styles from "./plan.module.scss";
import editIcon from "src/assets/edit.svg";
import trashIcon from "src/assets/trash.svg";
import { useEffect, useState } from "react";
import {
  getAllPlanAdditionalPurchaseApi,
  getUserDataByIdApi,
  patchPlanApi,
} from "src/_utils/api/api";
import { useSelector } from "react-redux";
import ActionModal from "src/_elements/actionModal/actionModal";

const Plan: React.FC<IPlan> = ({
  planData,
  setIsShowPlanEditModal,
  setSelectedPlanData,
  setIsShowAddSubmissionsModal,
  userId,
  setIsLoading,
}: IPlan) => {
  const observeAdditionalPurchaseAdded = useSelector(
    (state: any) => state.observeAdditionalPurchaseAdded
  );

  const {
    name,
    stripePrices,
    maxDomains,
    maxSubmissionsPerMonth,
    needToContactSales,
    canHidePoweredLabel,
    formPrice,
  } = planData;

  const [additionalPurchases, setAdditionalPurchases] = useState([]);
  const [planOwnerEmail, setPlanOwnerEmail] = useState("");
  const [isShowDeletePlanModal, setIsShowDeletePlanModal] = useState(false);

  useEffect(() => {
    getAllPlanAdditionalPurchaseApi(planData.id).then((res) => {
      setAdditionalPurchases(res.data.data);
    });
  }, [observeAdditionalPurchaseAdded]);

  useEffect(() => {
    userId &&
      getUserDataByIdApi(userId).then((res) => {
        setPlanOwnerEmail(res.data.data.email);
      });
  }, [userId]);

  const result = additionalPurchases.filter(
    (el: any) => el.planId === planData.id
  );

  const additionalPurhasesList = result.map((el: any, index: number) => (
    <div key={index} className={styles.purchaseItem}>
      <p>{el.name}</p>
    </div>
  ));

  const handleOpenAddSubmissionsModal = () => {
    setSelectedPlanData(planData);
    setIsShowAddSubmissionsModal(true);
  };

  const handleOpenEditPlanModal = () => {
    setSelectedPlanData(planData);
    setIsShowPlanEditModal(true);
  };

  const handleDeletePlan = () => {
    const payload = {
      active: false,
      isPublic: false,
    };

    patchPlanApi(payload, planData.id).then(() => {
      setIsLoading(true);
      handleCloseDeleteModal();
    });
  };

  const handleCloseDeleteModal = () => {
    setIsShowDeletePlanModal(false);
  };

  return (
    <>
      <div className={styles.planWrapper}>
        <ul>
          <li>
            Plan name: <span className={styles.planName}>{name}</span>
          </li>
          <li>
            <span>Max domains:</span> {maxDomains}
          </li>
          <li>
            <span>Price per month:</span>{" "}
            {stripePrices?.pricePerMonth.unit_amount}
          </li>
          <li>
            <span>Price per year:</span>{" "}
            {stripePrices?.pricePerYear.unit_amount}
          </li>
          <li>
            <span>Max submissions per month:</span> {maxSubmissionsPerMonth}
          </li>
          <li>
            <span>Need to contact sales:</span>{" "}
            {needToContactSales ? "yes" : "no"}
          </li>
          <li>
            <span>Can customize forms count:</span> {formPrice ? "yes" : "no"}
          </li>
          <li>
            <span>Can hide powered label:</span>{" "}
            {canHidePoweredLabel ? "yes" : "no"}
          </li>
          {planOwnerEmail && (
            <li>
              <span>User email:</span> {planOwnerEmail}
            </li>
          )}
          {additionalPurhasesList[0] && (
            <p className={styles.additionalPurchases}>Additional purchases:</p>
          )}
          <li>{additionalPurhasesList}</li>
        </ul>
        <div className={styles.buttonsWrapper}>
          <button
            onClick={handleOpenAddSubmissionsModal}
            className={styles.addSubmissions}
          >
            +
          </button>
          <button
            onClick={handleOpenEditPlanModal}
            className={styles.actionButton}
          >
            <img src={editIcon} alt="edit" />
          </button>
          <button
            onClick={() => setIsShowDeletePlanModal(true)}
            className={styles.actionButton}
          >
            <img src={trashIcon} alt="delete" />
          </button>
        </div>
      </div>
      <ActionModal
        title={`Delete "${planData.name}?" plan`}
        subtitle="This will permanently delete the plan."
        isShowModal={isShowDeletePlanModal}
        handleCloseModal={handleCloseDeleteModal}
        handleAction={handleDeletePlan}
        actionButtonText="Delete"
      />
    </>
  );
};

export default Plan;
