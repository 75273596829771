import styles from "./googleAdsItem.module.scss";
import trashIcon from "src/assets/trash.svg";
import editIcon from "src/assets/edit.svg";
import ActionModal from "src/_elements/actionModal/actionModal";
import { useState } from "react";
import { deleteGoogleAdsIdApi } from "src/_utils/api/api";
import GoogleAdsModal from "../googleAdsModal/googleAdsModal";
import { truncateText } from "src/_utils/truncateText";

const GoogleAdsItem: React.FC<any> = ({ item, setIsLoading }: any) => {
  const [isShowDeleteGoogleAdsModal, setIsShowDeleteGoogleAdsModal] =
    useState(false);
  const [isShowGoogleAdsModal, setIsShowGoogleAdsModal] = useState(false);

  const handleCloseGoogleAdsModal = () => {
    setIsShowGoogleAdsModal(false);
  };

  const handleCloseDeleteFbModal = () => {
    setIsShowDeleteGoogleAdsModal(false);
  };

  const handleDeleteFb = () => {
    deleteGoogleAdsIdApi(item.id).then(() => {
      setIsLoading(true);
      handleCloseDeleteFbModal();
    });
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.buttonsWrap}>
          <button>
            <img
              onClick={() => setIsShowGoogleAdsModal(true)}
              src={editIcon}
              alt="edit"
            />
          </button>
          <button onClick={() => setIsShowDeleteGoogleAdsModal(true)}>
            <img src={trashIcon} alt="delete" />
          </button>
        </div>
        <p className={styles.label}>Name:</p>
        <p className={styles.value}>{truncateText(item.displayName, 15)}</p>
        <p className={styles.label}>Conversion id:</p>
        <p className={styles.value}>{truncateText(item.conversionId, 15)}</p>
        <p className={styles.label}>Conversion label:</p>
        <p className={styles.value}>{truncateText(item.conversionLabel, 15)}</p>
      </div>
      <GoogleAdsModal
        isShow={isShowGoogleAdsModal}
        handleClose={handleCloseGoogleAdsModal}
        item={item}
        setIsLoading={setIsLoading}
        isEdit
      />
      <ActionModal
        title={`Delete "${item.displayName}?" google ads`}
        subtitle="This will permanently delete selected google ads."
        isShowModal={isShowDeleteGoogleAdsModal}
        handleCloseModal={handleCloseDeleteFbModal}
        handleAction={handleDeleteFb}
        actionButtonText="Delete"
      />
    </>
  );
};

export default GoogleAdsItem;
